import React, { useEffect, useState } from 'react'
import '../../styles/home.css'
import { axiosRequestSilentPublic } from '../../store/api-v1'
import { useSelector } from 'react-redux'
import Home_background from '../../assests/Home_background.png'

const Home = () => {

  const [data, setData] = useState()
  const store = useSelector((state)=>state.authencation)
  const fetchimages = async (id) => {
    const results = await axiosRequestSilentPublic("POST", "/lmsv2", "/getCollegeHomePageApiInfo", {
      "collegeId" : id
      }, {});
    if (results.data.gatewayResponse.status.isSuccess) {
          return results.data.gatewayResponse.response
    }
    return results.data
  }

  useEffect(()=>{
   if (store.profileInfo?.collegeId){
    fetchimages(store.profileInfo?.collegeId).then((res)=>{
      setData(res.CollegeHomePageApiInfo)
      
    })
   }
  },[store])
  return (
    <div className='h-100 pt-3'>
        <div className='home-banner'>
          
          
          <div className='home-banner-content'>
            {/* <div className='home-banner-profile'>

            </div> */}
            <div className='home-banner-text'>
            Hello <b>{store?.profileInfo?.name}</b>
            <p>{data && data[0]?.homePageCollegelogoText}</p>
            </div>
          
          </div>
          
          {/* <img src={Home_background} className='home-banner-logo' style={{width: '100%',height: '100%',position: 'absolute'}} alt="background-image"/> */}
          <div className='home-banner-footer'><p><b>Last login: {store?.profileInfo?.lastLoginDateTime}</b></p></div>
        </div>
        {/* <div className='home-introduction-box'>
         <div className='home-introduction-head-box'>
          <h5>Introduction Info</h5>
         </div>
         <div className='home-introduction-below-box'>
          <h6>Images/Text can we use</h6>
         </div>
        </div> */}
    </div>
  )
}

export default Home