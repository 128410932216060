import { Select, Input, Form, Button, Row, Col, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import "../../styles/Quizcreate.css";
import { createQuiz,createCustomCollegeQuiz } from "../../store/services/quizApi";
import { useSelector } from "react-redux";
import { getSubjectOptions } from "../../store/services/quizApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SelectCustomQuestions from "./SelectCustomQuestions";

const QuizCustomTest = () => {
  const [form] = Form.useForm();

  const [step, setStep] = useState(1);

  const [subjectsListOptions, setSubjectListOptions] = useState();

//   [
//     {
//         "seqNo": 100020,
//         "subjectId": 1000000,
//         "name": "Anatomy"
//     },
//     {
//         "seqNo": 100021,
//         "subjectId": 1000001,
//         "name": "Physiology"
//     },
//     {
//         "seqNo": 100022,
//         "subjectId": 1000002,
//         "name": "Biochemistry"
//     }
// ]

  const [subjectsList, setSubjectList] = useState();

  const [quizDetails, setQuizDetails] = useState();

  const authenticate = useSelector((state) => state.authencation);

  const [selectedQuestions, SetSelectQuestions] = useState();

  const navigate = useNavigate();

  const getRandomEqual = (value, parts) => {
    const number = value;
    const numberOfParts = parts;
    const equalPart = Math.floor(number / numberOfParts);
    var remaining = number % numberOfParts;

    var parts = [];

    // Add the equal parts
    for (var i = 0; i < numberOfParts; i++) {
      parts.push(equalPart);
    }

    // Distribute the remaining value randomly
    for (var i = 0; i < remaining; i++) {
      var randomIndex = Math.floor(Math.random() * numberOfParts);
      parts[randomIndex]++;
    }

    return parts;
  };

  const handleNoOfQuestions = (e) => {
    let parts = getRandomEqual(e.target.value, 3);
    form.setFieldsValue({
      simpleCount: parts[0],
      mediumCount: parts[1],
      complexCount: parts[2],
    });
  };

  const handlequiz = (values) => {
    setStep(2);
    setQuizDetails(values);
    let total = getRandomEqual(values?.noOfQuestions, values.subjects.length);

    const updatedSubjects = selectedQuestions.map((subj, index) => {
      return {
        ...subj,
        totalNoOfQuestions: total[index],
      };
    });

    setSubjectList(updatedSubjects);
  };

  const handleSubmitQuiz = () => {
    let body = {
      facultyId: authenticate?.profileInfo?.facultyId,
      quizName: quizDetails?.quizName,
      totalNoOfQuestions: parseInt(quizDetails?.noOfQuestions),
      quizSubject: subjectsList,
    };

    createCustomCollegeQuiz(body).then((res) => {
      if (res?.quizId) {
        navigate("/quiz/list/customselect", {
          state: { quizId: res?.quizId },
        });
      }
    });
  };

  const handleQuest = () => {
    navigate(`/quiz/list/customselect`,{state:{quizId: 100028}});
  }

  const selectedSubjects = (e) => {

    let subjects = subjectsListOptions.filter((item) =>
      e.includes(item.subjectId)
    );
    console.log(subjects);
    SetSelectQuestions(subjects);
    // if (step == 2) {
    //   form.submit();
    // }
  };

  useEffect(() => {
    if (authenticate?.isAuthenticated) {
      getSubjectOptions({
        facultyId: authenticate?.profileInfo?.facultyId,
      }).then((res) => {
        setSubjectListOptions(res?.collegeFacultySubject);
      });
    }
  }, [authenticate]);

  const handleSubjectNumbers = (e, name, key) => {
    setSubjectList((prevData) => {
      return prevData.map((item) => {
        if (item.name === name) {
          return { ...item, [key]: parseInt(e.target.value || 0) };
        }
        return item;
      });
    });
  };

  return (
    <>
      <div className="shadow p-2 quiz-card">
        <Form form={form} colon={false} layout="vertical" onFinish={handlequiz} style={{border : '2px solid lightgrey', borderRadius: '10px',padding: '10px'}}>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Quiz name"
                name="quizName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Total No of Question"
                name="noOfQuestions"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Enter Count"
                  onChange={(e) => handleNoOfQuestions(e)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Select Subjects"
                name="subjects"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="select any"
                  onChange={selectedSubjects}
                >
                  {subjectsListOptions?.map((item) => {
                    return (
                      <Select.Option value={item?.subjectId}>
                        {item?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div className="d-flex">
            <Button htmlType="submit" style={{ marginLeft: "auto" }}>
              Next
            </Button>
          </div>
        </Form>
      </div>

      <>
        {step == 2 ? (
          <div className="shadow p-2 pb-4 quiz-card">
            {/* <table className="quiz-subjects-table">
              <tr>
                <th>Subject</th>
                <th>Questions</th>
              </tr> */}
              {subjectsList?.map((item) => {
                return (
                  // <tr>
                  //   <td>{item?.name}</td>
                  //   <td>
                  //     <Input
                  //       name={item?.name + "_total"}
                  //       value={item?.totalNoOfQuestions}
                  //       onChange={(e) =>
                  //         handleSubjectNumbers(
                  //           e,
                  //           item?.name,
                  //           "totalNoOfQuestions"
                  //         )
                  //       }
                  //     />
                  //   </td>
                  // </tr>
                  <CustomSubjects item={item} handleSubjectNumbers={handleSubjectNumbers}/>
                );
              })}
            {/* </table> */}
            <div className="d-flex mt-2">
              <Button
                onClick={() => handleSubmitQuiz()}
                style={{ marginLeft: "auto" }}
              >
                Save & Next
              </Button>
            </div>
          </div>
        ) : null}
      </>
      {/* <Button onClick={()=> {handleQuest()}}>Test</Button> */}
    </>
  );
};

export default QuizCustomTest;


const CustomSubjects = ({ item, handleSubjectNumbers }) => {
  const [topicOptions, setTopicOptions] = useState([]);

  const [moduleOptions, setModuleOptions] = useState([]);

  // const fetchTopics = () => {
  //   getTopicsInSubject({
  //     subjectId: item?.subjectId,
  //   }).then((res) => {
  //     setTopicOptions(res?.topic);
  //   });
  // };

  // useEffect(() => {
  //   fetchTopics();
  // }, []);

  const handleChange = (field, value) => {
    if (field === "topics" && value !== null) {

      // const promises = value.map(value =>
      //   getModulesInTopic({ topicId: value })
      // );

      // Promise.all(promises).then((responses)=>{
      //   console.log(responses)
      //   const combinedModules = responses.reduce((acc, res) => {
      //     if (res && res.module && Array.isArray(res.module)) {
      //       acc.push(...res.module);
      //     }
      //     return acc;
      //   }, []);

      //   console.log(combinedModules)
      //   setModuleOptions(combinedModules); 
      // })
    
    } else if (field === "module" && value !== null) {
    }
  };
  return (
    <div className="mb-4 p-2" style={{border: '2px solid lightgrey',borderRadius: '10px'}}>
      <div className="d-flex justify-content-between align-items-center">
        <div>Subject Name: <b>{item?.name}</b></div>
      <div className="d-flex justify-content-between align-items-center" style={{gap:'10px'}}>
        No of Questions:
        <Input
          name={item?.name + "_total"}
          value={item?.totalNoOfQuestions}
          style={{width:'100px'}}
          onChange={(value) =>
            handleSubjectNumbers(value, item?.name, "totalNoOfQuestions")
          }
        />
      </div>
      </div>
      {/* <div className="mb-3" >
        <b>Select Topics</b>
      </div>
      <div className="mb-3">
        <Select
          mode="multiple"
          placeholder="Select Topics"
          style={{width: '100%'}}
          onChange={(value) => handleChange("topics", value)}
        >
          {topicOptions?.map((item) => {
            return (
              <Select.Option value={item?.topicId}>{item?.name}</Select.Option>
            );
          })}
        </Select>
      </div>
      <div className="mb-3" >
        <b>Select Modules</b>
        
      </div>
      <div className="mb-3">
        <Select
          mode="multiple"
          placeholder="Select Module"
          style={{width: '100%'}}
          onChange={(value) =>
            handleSubjectNumbers(value, item?.name, "subjectModule")
          }
        >
          {moduleOptions?.map((item) => {
            return (
              <Select.Option value={item?.moduleId}>{item?.name}</Select.Option>
            );
          })}
        </Select>
      </div> */}
    </div>
  );
};
