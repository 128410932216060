import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Table,
  Tag,
  Form,
  Input,
  Select,
  Row,
  Col,
  Modal,
} from "antd";
import {
  axiosRequestSilent,
  axiosRequestSilentPublic,
  axiosRequestNA,
} from "../../store/api-v1";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faRefresh,
  faLink,
  faLinkSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  getFaculty,
  addFaculty,
  deleteFaculty,
  updateFaculty,
  getSubjects,
  AddSubjectToFaculty,
  getFacultySubject,
  deleteFacultySubject,
} from "../../store/services/facultyApi";
import { getAllCourse } from "../../store/services/studentApi";
import "../../styles/faculty.css";

const Faculty = () => {
  const [data, setData] = useState();

  const [form] = Form.useForm();

  const authenticate = useSelector((state) => state.authencation);

  const [facultyEdit, setFacultyEdit] = useState(false);

  const [showAddFaculty, setShowAddFaculty] = useState(false);

  const [facultyEditDetails, setFacultyEditDetails] = useState();

  const [facultySubjectModal, setFacultySubjectModal] = useState(false);

  const [addfacultydetails, setAddFacultyDetails] = useState();

  const [facultySubjectEdit, setFacultySubjectEdit] = useState(false);

  const columns = [
    {
      title: "S/no",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile",
      dataIndex: "mobileNo",
      key: "mobileNo",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Subjects",
      dataIndex: "subjects",
      key: "subjects",
      render: (text) => <div style={{ wordBreak: 'break-word' }}>{text}</div>
      // ellipsis: true,  
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space wrap>
          <Button onClick={() => handleDelete(record)} title="Faculty Delete">
            <FontAwesomeIcon icon={faTrash} color="red" />
          </Button>
          <Button onClick={() => handleEdit(record)} title="Faculty Edit">
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button onClick={() => handleLinkSubject(record, "Add")} title="Subject Link">
            <FontAwesomeIcon icon={faLink} />
          </Button>
          <Button onClick={() => handleLinkSubject(record, "delete")} title="Subject De-Link">
            <FontAwesomeIcon icon={faLinkSlash} />
          </Button>
        </Space>
      ),
    },
  ];



  const fetchFaculty = () => {
    getFaculty({
      facultyId: authenticate?.profileInfo?.facultyId,
      collegeId: authenticate?.profileInfo?.collegeId,
    }).then((res) => setData(res?.collegeFaculty));
  };

  const handleSubmitFaculty = (values) => {
    if (authenticate?.isAuthenticated) {
      const body = {
        collegeId: authenticate?.profileInfo?.collegeId,
        userType: values.userType,
        name: values.name,
        countryCode: 91,
        mobileNo: values.mobile,
        email: values.email,
        education: values.education,
        collegeAdmin: true,
      };
      if (facultyEdit) {
        body["facultyId"] = facultyEditDetails["facultyId"];
        // console.log(body)
        updateFaculty(body).then((res) => {
          form.resetFields();
          setFacultyEditDetails({});
          setFacultyEdit(false);
          fetchFaculty();
        });
      } else {
        addFaculty(body).then((res) => {
          form.resetFields();
          fetchFaculty();
        });
      }
    }
  };

  const handleDelete = (values) => {
    const body = {
      facultyId: values?.facultyId,
    };

    deleteFaculty(body).then((res) => fetchFaculty());
  };

  const handleEdit = (value) => {
    setFacultyEdit(true);
    setFacultyEditDetails(value);
    form.setFieldsValue({
      id: value.id,
      name: value.name,
      mobile: value.mobileNo,
      email: value.email,
      userType: value.userType,
      education: value.education,
    });
  };

  const handleCloseModal = () => {
    setFacultySubjectModal(false);
    setFacultySubjectEdit(false);
    setAddFacultyDetails(null);
    fetchFaculty();
  };

  const handleLinkSubject = (value, action) => {
    setFacultySubjectModal(true);
    setAddFacultyDetails(value);
    if (action == "delete") {
      setFacultySubjectEdit(true);
    }
  };

  useEffect(() => {
    if (
      authenticate?.isAuthenticated &&
      authenticate?.profileInfo?.collegeAdmin
    ) {
      fetchFaculty();
    }
  }, [authenticate]);

  return (
    
    <div style={{ padding: "20px" }}>
      {authenticate?.profileInfo?.userType == "Admin" ? (
        <>
          {showAddFaculty == true ? <div className="faculty-add-box">
            <p>
              <b>Faculty</b>
            </p>
            <div style={{ background: "white", padding: "10px", width: "80%" }}>
              <Form
                colon={false}
                layout="vertical"
                onFinish={handleSubmitFaculty}
                form={form}
              >
                <Row gutter={16}>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      label="Name"
                      name="name"
                      style={{ fontWeight: "bold" }}
                    >
                      <Input
                        style={{ background: "#D0E7F3", height: "40px" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      label="Mobile"
                      name="mobile"
                      style={{ fontWeight: "bold" }}
                    >
                      <Input
                        style={{ background: "#D0E7F3", height: "40px" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      label="Email"
                      name="email"
                      style={{ fontWeight: "bold" }}
                    >
                      <Input
                        style={{ background: "#D0E7F3", height: "40px" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      label="User Type"
                      name="userType"
                      style={{ fontWeight: "bold" }}
                    >
                      <Select style={{ background: "#D0E7F3", height: "40px", borderRadius: '5px' }}>
                        <Select.Option value="HOD">HOD</Select.Option>
                        <Select.Option value="Professor">Professor</Select.Option>
                        <Select.Option value="Assistant Professor">Assistant Professor</Select.Option>
                        <Select.Option value="Principal">Prinicipal</Select.Option>
                        <Select.Option value="Dean">Dean</Select.Option>   
                        {/* <Select.Option value="Admin">Admin</Select.Option>         */}
                        <Select.Option value="Faculty">Faculty</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      label="Education"
                      name="education"
                      style={{ fontWeight: "bold" }}
                    >
                      <Select style={{ background: "#D0E7F3", height: "40px", borderRadius: '5px' }}>
                        <Select.Option value="PG">PG</Select.Option>
                        {/* <Select.Option value="MBBS">MBBS</Select.Option>
                        <Select.Option value="MD">MD</Select.Option>
                        <Select.Option value="MS">MS</Select.Option> */}
                      </Select>
                    </Form.Item>
                  </Col>
                  {facultyEdit ? (
                    <Col span={8}>
                      <Form.Item label={true}>
                        <Space>
                          <Button
                            // htmlType="reset"
                            style={{
                              background: "#8AE9FF",

                              height: "40px",
                            }}
                            onClick={() => {
                              setFacultyEdit(false);
                              form.resetFields();
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                          </Button>
                          <Button
                            htmlType="submit"
                            style={{
                              background: "#8AE9FF",

                              height: "40px",
                            }}
                          >
                            Edit Faculty
                          </Button>
                        </Space>
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col span={8}>
                      <Form.Item label={true}>
                        <Space>
                          <Button
                            htmlType="reset"
                            style={{ background: "#8AE9FF", height: "40px" }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                          </Button>
                          <Button
                            htmlType="submit"
                            style={{ background: "#8AE9FF", height: "40px" }}
                          >
                            Save Faculty
                          </Button>
                        </Space>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Form>
            </div>
          </div> : <div className="flex-row-reverse d-flex"><Button style={{ background: "orange", color: "#fff" }}
            onClick={() => setShowAddFaculty(true)}>+ Add Faculty</Button></div>}

          <div style={{ width: "100%" }} className="mt-4">
            <p>
              <b>Faculty List</b>
            </p>
            <Table columns={columns} dataSource={data} pagination={false} scroll={{
      y: 390,
    }} />
          </div>
          <Modal
            footer={null}
            open={facultySubjectModal}
            title={facultySubjectEdit ? "Delete Subject" : "Add Subject"}
            onOk={() => {
              handleCloseModal();
            }}
            onCancel={() => {
              handleCloseModal();
            }}
          >
            {facultySubjectEdit ? (
              <FacultyDelinkSubject
                addfacultydetails={addfacultydetails}
                facultySubjectEdit={facultySubjectEdit}
              />
            ) : (
              <FacultyLinkSubject
                addfacultydetails={addfacultydetails}
                setAddFacultyDetails={setAddFacultyDetails}
                facultySubjectEdit={facultySubjectEdit}
              />
            )}
          </Modal>
        </>
      ) : (
        <div>No Access</div>
      )}
    </div>
  );
};

export default Faculty;

const FacultyLinkSubject = ({
  addfacultydetails,
  setAddFacultyDetails,
  facultySubjectEdit,
}) => {
  const [form] = Form.useForm();
  const [courseOptions, setCourseOptions] = useState();
  const [subjectOptions, setSubjectOptions] = useState();

  const authenticate = useSelector((state) => state.authencation);

  // const [selectedCourse, setSelectedCourse] = useState()
  // const [selectedSubject, setSelectedSubject] = useState()

  const handleSubmit = (values) => {
    AddSubjectToFaculty({
      subjectId: values.subjects,
      facultyId: addfacultydetails?.facultyId,
    }).then((res) => {
      form.resetFields();
    });
  };

  useEffect(() => {
    getAllCourse({
      collegeId: authenticate?.profileInfo?.collegeId,
    }).then((res) => setCourseOptions(res?.collegeCourse));
  }, []);

  const getSubject = (value) => {
    // if (form.course) {
    form.resetFields(["subjects"]);
    getSubjects({
      courseId: value,
    }).then((res) => {
      setSubjectOptions(res?.courseSubject);
    });
    // }
  };

  return (
    <div>
      <Form form={form} colon={false} layout="vertical" onFinish={handleSubmit}>
        <Form.Item label="course" name="course">
          <Select onChange={(value) => getSubject(value)}>
            {courseOptions?.map((item) => {
              return (
                <Select.Option value={item.courseId}>{item.name}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Subjects" name="subjects">
          <Select>
            {subjectOptions?.map((item) => {
              return (
                <Select.Option value={item.subjectId}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Button htmlType="submit">Submit</Button>
      </Form>
    </div>
  );
};

const FacultyDelinkSubject = ({ facultySubjectEdit, addfacultydetails }) => {
  const [subjects, setSubjects] = useState();

  useEffect(() => {
    if (facultySubjectEdit) {
      getFacultySubject({ facultyId: addfacultydetails?.facultyId }).then(
        (res) => setSubjects(res?.collegeFacultySubject)
      );
    }
  }, [addfacultydetails]);

  const handleDelete = (seqNo) => {
    deleteFacultySubject({ seqNo: seqNo }).then((res) => {
      getFacultySubject({ facultyId: addfacultydetails?.facultyId }).then(
        (res) => setSubjects(res?.collegeFacultySubject)
      );
    });
  };

  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      {subjects?.map((subject) => {
        return (
          <div className="faculty-delink-subject">
            {subject?.name}
            <div className="delink-icon">
              <FontAwesomeIcon
                icon={faTrash}
                color={"red"}
                onClick={() => handleDelete(subject?.seqNo)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
