import { Button, Checkbox } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { axiosRequestSilentPublic } from "../../store/api-v1";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  deleteCollegeQuiz,
  updateCollegeQuizName,
  getCustomCollegeQuiz,
  deleteCollegeCustomQuiz
} from "../../store/services/quizApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const QuizList = () => {
  const [quizList, setQuizList] = useState([]);

  const location = useLocation()

  const [quizType, setQuizType] = useState(location?.state?.quiztype || "System Generate");

  const store = useSelector((state) => state);

  const navigate = useNavigate();

  const getQuizList = async (body) => {
    try {
      const res = await axiosRequestSilentPublic(
        "POST",
        "/lmsv2",
        "/getCollegeQuiz",
        body,
        {}
      );
      if (res.data.gatewayResponse.status.isSuccess) {
        return res.data.gatewayResponse.response;
      } else {
        toast.error(res.data.gatewayResponse.status.message);
        return res.data.gatewayResponse;
      }
    } catch (error) {
      throw new Error("Could not fetch Quiz list");
    }
  };

  const handlePost = (quiz) => {
    navigate(`/quiz/post`, { state: { quiz: quiz, post: true } });
  };

  const handleDeleteCollegeQuiz = (quizId) => {
    const body = {
      facultyId: store.authencation.profileInfo.facultyId,
      quizId: quizId,
    };
    if (quizType == "System Generate") {
      deleteCollegeQuiz(body).then((res) => {
        getQuizList({
          facultyId: store.authencation.profileInfo.facultyId,
        }).then((res) => setQuizList(res.quiz));
      });
    }
    if (quizType == "Custom Quiz") {
      deleteCollegeCustomQuiz(body).then((res) => {
        getCustomCollegeQuiz({
          facultyId: store.authencation.profileInfo.facultyId,
        }).then((res) => setQuizList(res.quiz));
      });
    }
    
  };

  useEffect(() => {
    if (store.authencation?.isAuthenticated) {
      if (quizType == "System Generate") {
        getQuizList({
          facultyId: store.authencation.profileInfo?.facultyId,
        }).then((res) => setQuizList(res.quiz));
      }
      if (quizType == "Custom Quiz") {
        getCustomCollegeQuiz({
          facultyId: store.authencation.profileInfo?.facultyId,
        }).then((res) => {setQuizList(res.quiz);console.log(res?.quiz)});
      }
    }
  }, [store.authencation,quizType]);

  return (
    <div style={{ padding: "30px 0 0 50px " }}>
      <div className="d-flex mb-4">
        {/* <div className="mr-2">
          <p><b>Quiz Type</b></p>
        </div> */}
        <div>
          <Checkbox
            checked={quizType == "System Generate" ? true : false}
            onClick={() => setQuizType("System Generate")}
          >
            System Generate
          </Checkbox>
        </div>
        <div>
          <Checkbox
            checked={quizType == "Custom Quiz" ? true : false}
            onClick={() => setQuizType("Custom Quiz")}
          >
            Custom Quiz
          </Checkbox>
        </div>
      </div>
      <div className="quizlist p-2 d-flex gap-3 flex-column" style={{maxHeight: '75vh',overflow: 'auto',background: 'transparent'}}>
        { quizList?.length == 0 || quizList == undefined ?
        <div className="text-center">
          <h3></h3>
        </div>
        
         : quizList?.map((item) => {
          return (
            <QuizListBox
              item={item}
              handleDeleteCollegeQuiz={handleDeleteCollegeQuiz}
              handlePost={handlePost}
              quizType={quizType}
            />
          );
        })}
      </div>
    </div>
  );
};

export default QuizList;

const QuizListBox = ({ item, handleDeleteCollegeQuiz, handlePost , quizType}) => {
  const [quiz, setQuiz] = useState(item);

  const inputref = useRef();

  const authencation = useSelector((state) => state.authencation);

  useEffect(() => {
    setQuiz(item);
  }, [item]);

  const handleNameChange = (e) => {
    const updatedQuiz = { ...quiz, quizName: e.target.value };
    setQuiz(updatedQuiz);
  };

  const handleBlurFunction = () => {
    updateCollegeQuizName({
      quizId: parseInt(quiz.quizId),
      facultyId: authencation?.profileInfo?.facultyId,
      quizName: quiz?.quizName,
    }).then((res) => {
      toast.success("Quiz name updated");
    });
  };

  return (
    <div className="quiz-list-card shadow">
      <div className="quiz-list-details">
        <div>
          Quiz Name:{" "}
          <span>
            <input
              value={quiz.quizName}
              ref={inputref}
              onChange={(e) => handleNameChange(e)}
              onBlur={() => handleBlurFunction()}
              style={{ width: "120px", border: "none" }}
            />
            &nbsp;&nbsp;
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => inputref.current.focus()}
            />
          </span>
        </div>
        <div>
          Subject: <span>{quiz.subjects}</span>
        </div>
      </div>
      <div className="quiz-list-details">
        <div>
          Total No Of Questions: <span>{quiz.totalNoOfQuestions}</span>
        </div>
        <div>
          Quiz Date: <span>{quiz.quizDate}</span>
        </div>
      </div>
      <div className="quiz-list-actions">
        <Button>
          <Link to={quizType == "System Generate"?"/quiz/list/quizquestions":"/quiz/list/customselect"} state={{ quizId: quiz?.quizId }}>
            Get Questions
          </Link>
        </Button>
        {/* <Button disabled>Analysis</Button> */}
        <Button onClick={() => handleDeleteCollegeQuiz(quiz?.quizId)}>
          Delete
        </Button>
        {quiz?.postAllowed ? (
          <Button disabled={!quiz.postAllowed} onClick={() => handlePost(quiz)}>
            Post
          </Button>
        ) : null}
        {quiz?.repostAllowed ? (
          <Button onClick={() => handlePost(quiz)}>Repost</Button>
        ) : null}
      </div>
    </div>
  );
};
