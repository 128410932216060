import { axiosRequestSilentPublic } from "../api-v1";
import { toast } from "react-toastify";

const makeApiRequest = async (method, url, endpoint, body,success_msg=false) => {
  try {
    const res = await axiosRequestSilentPublic(method, url, endpoint, body, {});
    if (res.data.gatewayResponse.status.isSuccess) {
      if (success_msg){
        toast.success(res.data.gatewayResponse.status.message)
      }
    
      return res.data.gatewayResponse.response;
    } else {
      toast.error(res.data.gatewayResponse.status.message);
      return res.data.gatewayResponse;
    }
  } catch (error) {
    throw new Error(`API Request Error: ${error.message}`);
  }
};

export const getAllCourse = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/getAllCourse", body,false);
};

export const getAllCourseSpecialization = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/getAllCourseSpecialization", body, false);
};

export const getAllCourseSpecializationBatch = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/getAllCourseSpecializationBatch", body,false);
};

export const getAllCourseSpecializationBatchAcadamicYear = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/getAllCourseSpecializationBatchAcademicYear", body,false);
};

export const addStudent = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/createCollegeStudent", body,true);
};

export const deleteStudent = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/deleteCollegeStudent", body, true);
};

export const updateStudent = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/updateCollegeStudent", body,true);
};

export const getStudentAnalysis = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/getStudentAnalytics", body,false);
};

// export const getAllCourse = async (body) => {
//   try {
//     const res = await axiosRequestSilentPublic(
//       "POST",
//       "/lmsv2",
//       "/getAllCourse",
//       body,
//       {}
//     );
//     if (res.data.gatewayResponse.status.isSuccess) {
//       return res.data.gatewayResponse.response;
//     } else {
//       toast.error(res.data.gatewayResponse.status.message);
//       return res.data.gatewayResponse;
//     }
//   } catch (error) {
//     throw new Error("Could not delete faculty");
//   }
// };

// export const getAllCourseSpecialization = async (body) => {
//   try {
//     const res = await axiosRequestSilentPublic(
//       "POST",
//       "/lmsv2",
//       "/getAllCourseSpecialization",
//       body,
//       {}
//     );
//     if (res.data.gatewayResponse.status.isSuccess) {
//       return res.data.gatewayResponse.response;
//     } else {
//       toast.error(res.data.gatewayResponse.status.message);
//       return res.data.gatewayResponse;
//     }
//   } catch (error) {
//     throw new Error("Could not delete faculty");
//   }
// };

// export const getAllCourseSpecializationBatch = async (body) => {
//   try {
//     const res = await axiosRequestSilentPublic(
//       "POST",
//       "/lmsv2",
//       "/getAllCourseSpecializationBatch",
//       body,
//       {}
//     );
//     if (res.data.gatewayResponse.status.isSuccess) {
//       return res.data.gatewayResponse.response;
//     } else {
//       toast.error(res.data.gatewayResponse.status.message);
//       return res.data.gatewayResponse;
//     }
//   } catch (error) {
//     throw new Error("Could not delete faculty");
//   }
// };

// export const getAllCourseSpecializationBatchAcadamicYear = async (body) => {
//   try {
//     const res = await axiosRequestSilentPublic(
//       "POST",
//       "/lmsv2",
//       "/getAllCourseSpecializationBatchAcademicYear",
//       body,
//       {}
//     );
//     if (res.data.gatewayResponse.status.isSuccess) {
//       return res.data.gatewayResponse.response;
//     } else {
//       toast.error(res.data.gatewayResponse.status.message);
//       return res.data.gatewayResponse;
//     }
//   } catch (error) {
//     throw new Error("Could not delete faculty");
//   }
// };

// export const addStudent = async (body) => {
//   try {
//     const res = await axiosRequestSilentPublic(
//       "POST",
//       "/lmsv2",
//       "/createCollegeStudent",
//       body,
//       {}
//     );
//     if (res.data.gatewayResponse.status.isSuccess) {
//       return res.data.gatewayResponse.response;
//     } else {
//       toast.error(res.data.gatewayResponse.status.message);
//       return res.data.gatewayResponse;
//     }
//   } catch (error) {
//     throw new Error("Could not create Student");
//   }
// };

// export const deleteStudent = async (body) => {
//   try {
//     const res = await axiosRequestSilentPublic(
//       "POST",
//       "/lmsv2",
//       "/deleteCollegeStudent",
//       body,
//       {}
//     );
//     if (res.data.gatewayResponse.status.isSuccess) {
//       return res.data.gatewayResponse.response;
//     } else {
//       toast.error(res.data.gatewayResponse.status.message);
//       return res.data.gatewayResponse;
//     }
//   } catch (error) {
//     throw new Error("Could not delete Student");
//   }
// };

// export const updateStudent = async (body) => {
//   try {
//     const res = await axiosRequestSilentPublic(
//       "POST",
//       "/lmsv2",
//       "/updateCollegeStudent",
//       body,
//       {}
//     );
//     if (res.data.gatewayResponse.status.isSuccess) {
//       return res.data.gatewayResponse.response;
//     } else {
//       toast.error(res.data.gatewayResponse.status.message);
//       return res.data.gatewayResponse;
//     }
//   } catch (error) {
//     throw new Error("Could not Update Student");
//   }
// };
