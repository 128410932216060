import { configureStore } from "@reduxjs/toolkit"
import  loginSlice  from './authSlice/loginSlice'
import  homeSlice  from './HomeSlice'

export const store = configureStore({
    reducer: {
        authencation: loginSlice,
        home: homeSlice
    },
    // middleware: getDefaultMiddleware =>
    //     getDefaultMiddleware().concat(apiSlice.middleware),
    // devTools: true
})