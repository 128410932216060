import { axiosRequestSilentPublic } from "../api-v1";
import { toast } from "react-toastify";

const makeApiRequest = async (method, url, endpoint, body,success_msg=false,headers={}) => {
  try {
    const res = await axiosRequestSilentPublic(method, url, endpoint, body,{headers: {...headers}});
    if (res.data.gatewayResponse?.status?.isSuccess) {
      if (success_msg && res?.data?.gatewayResponse?.status ){
        toast.success(res?.data?.gatewayResponse?.status?.message)
      }
      return res?.data?.gatewayResponse?.response;
    } else {
      toast.error(res.data?.gatewayResponse?.status?.message);
      return res.data.gatewayResponse;
    }
  } catch (error) {
    throw new Error(`API Request Error: ${error.message}`);
  }
};

export const ongoing = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/ongoing", body,false);
};

export const upcoming = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/upcoming", body,false);
};

export const completed = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/completed", body,false);
};

export const getQuizResult = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/getQuizResult", body,false);
};

export const createQuiz = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/createCollegeQuiz", body,false);
};

export const getQuizQuestions = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/getAllQuestionsInQuiz", body,false);
};

export const replaceQuestion = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/replaceMCQ", body,false);
};

export const deleteCollegeQuiz = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/deleteCollegeQuiz", body,false);
};

export const deleteCollegeCustomQuiz = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/deleteCustomCollegeQuiz", body,false);
};

export const getSubjectOptions = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/getCollegeFacultySubject", body,false);
};

export const cancelUpcomingQuiz = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/cancelUpcomingOngoingQuiz", body,true);
};

export const postQuiz = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/postQuiz", body,true);
};

export const updateQuiz = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/updateUpcomingQuiz", body,true);
};

export const updateCollegeQuizName = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/updateCollegeQuizName", body,false);
};

export const selectMCQInCustomQuizSubject = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/selectMCQInCustomQuizSubject", body,true);
};

export const createCustomCollegeQuiz = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/createCustomCollegeQuiz", body,false);
};

export const getTopicsInSubject = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/getTopicsInSubject", body,false);
};


export const getModulesInTopic = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/getModulesInTopic", body,false);
};

export const getQuestionsInModule = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/getQuestionsInModule", body,false);
};

export const getCustomCollegeQuiz = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/getCustomCollegeQuiz", body,false);
};

export const getAllQuestionsInCustomQuiz = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/getAllQuestionsInCustomQuizSubject", body,false);
};

export const replaceCustomMCQ = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/replaceCustomMCQ", body,false);
};

export const addQuestion = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/addCustomMCQ", body,false);
};

export const editQuestion = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/editCustomMCQ", body,false);
};

export const deleteAddedQuestion = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/deleteCustomQuizQuestion", body,false);
};

export const downloadResults = async (body) => {
  return makeApiRequest("POST", "/lmsv2", "/getQuizRankersListDownloadProd", body,false);
};

export const studentReview = async (body) => {
  return makeApiRequest("POST", "/leaderBoardv2", "/reviewQuiz", body,false,{Channelid: 'App',Sessiontoken: 'abc'});
};


