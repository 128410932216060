import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    homePageCollegelogo: null,
    homePageCollegelogoText: null,
    homePageDTlogo: null,
    homePageDTlogoText: null
};

const homeSlice = createSlice({
  name: "home_assests",
  initialState,
  reducers: {
    Home_API_SUCCESS: (state,action) => {
        state.homePageCollegelogo = action.homePageCollegelogo
        state.homePageCollegelogoText = action.homePageCollegelogoText
        state.homePageDTlogo = action.homePageDTlogo
        state.homePageDTlogoText = action.homePageDTlogoText
    },
    
  },
});

export const { Home_API_SUCCESS } = homeSlice.actions;

export default homeSlice.reducer;
