import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getStudentAnalysis } from "../../store/services/studentApi";
import { Table } from "antd";
import "../../styles/student-analysis.css";
import { Link } from "react-router-dom";

const StudentAnalysis = () => {
  const location = useLocation();

  const [quizList, setQuizList] = useState();

  const columns = [
    {
      title: "Quiz Name",
      dataIndex: "quizName",
      key: "quizName",
    },
    {
        title: "Quiz Date",
        dataIndex: "quizDate",
        key: "quizDate",
    },
    {
      title: "Rank",
      dataIndex: "allIndiaRank",
      key: "allIndiaRank",
    },
    // {
    //   title: "State Rank",
    //   dataIndex: "stateRank",
    //   key: "stateRank",
    // },
    // {
    //   title: "State Name",
    //   dataIndex: "stateName",
    //   key: "stateName",
    // },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Correct",
      dataIndex: "correct",
      key: "correct",
    },
    {
      title: "Wrong",
      dataIndex: "wrong",
      key: "wrong",
    },
    {
      title: "Skipped",
      dataIndex: "skipped",
      key: "skipped",
    },
    // {
    //   title: "Guessed",
    //   dataIndex: "guessed",
    //   key: "guessed",
    // },
    {
      title: "Time Spent",
      dataIndex: "timeSpent",
      key: "timeSpent",
    },
  ];

  useEffect(() => {
    getStudentAnalysis({ studentId: location?.state?.student?.studentId }).then((res) => {
      setQuizList(res?.quiz);
    });
  }, []);

  return (
    <div className="p-4">
      <div className="mb-4">
        <Link to="/students"> Back to list</Link>
      </div>
        <div className="mb-4">
            <h2>{location?.state?.student?.name}</h2>
        </div>
       
      <div className="d-flex justify-content-between align-items-center p-1 mb-3">
        <div
          className="analysis-block"
          
        >
          <div className="d-flex justify-content-center align-items-center">
            Attended Quizes
          </div>
          <div className="d-flex justify-content-center align-items-center">
            {(quizList?.length || 0)}
          </div>
        </div>
        <div
          className="green analysis-block"
          
        >
          <div className="d-flex justify-content-center align-items-center">
            Avg correct marks
          </div>
          <div className="d-flex justify-content-center align-items-center">
            { quizList?.length > 0 ?
            parseInt(quizList.reduce((sum, result) => {
              return sum + (result?.correct || 0);
            }, 0)/quizList?.length) : 0}
          </div>
        </div>
        <div
          className="analysis-block red"
          
        >
          <div className="d-flex justify-content-center align-items-center">
            Avg wrong marks
          </div>
          <div className="d-flex justify-content-center align-items-center">
          { quizList?.length > 0 ? 
            parseInt(quizList.reduce((sum, result) => {
              return sum + (result?.wrong || 0);
            }, 0)/quizList?.length) : 0}
          </div>
        </div>
        <div
          className="analysis-block blue"
          
        >
          <div className="d-flex justify-content-center align-items-center">
            Avg time spent
          </div>
          <div className="d-flex justify-content-center align-items-center">
          { quizList?.length > 0 ?
            parseInt(quizList.reduce((sum, result) => {
              return sum + (result?.timeSpent || 0);
            }, 0)/quizList?.length) : 0 }
          </div>
        </div>
      </div>
      <div className="analysis-table">
        <Table
        //   scroll={{
        //     x: 1000,
        //     y: 500,
        //   }}
          
          columns={columns}
          dataSource={quizList}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default StudentAnalysis;
