import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/login";
// import Sidebar from "../components/Sidebar";
// import Header from "../components/Header";
import { PrivateRoute } from "./PrivateRoute";
import Dashboard from "../pages/dashboard/dashboard";
import Faculty from "../pages/dashboard/Faculty";
import Students from "../pages/dashboard/Students";
import ProtectRoute from "./ProtectRoute";
import Home from "../pages/dashboard/Home";
import Leaderboard from "../pages/dashboard/Leaderboard";
import Quiz from "../pages/dashboard/Quiz";
import QuizList from "../pages/dashboard/QuizList";
import QuizCreate from "../pages/dashboard/QuizCreate";
import QuizUpload from "../pages/dashboard/QuizUpload";
import LeaderboardResult from "../pages/dashboard/Leaderboard-result";
import QuizQuestions from "../pages/dashboard/QuizQuestions";
import QuizPost from "../pages/dashboard/QuizPost";
import Profile from "../pages/dashboard/Profile";
import SelectCustomQuestions from "../pages/dashboard/SelectCustomQuestions";
import StudentAnalysis from "../pages/dashboard/StudentAnalysis";
import LeaderboardReview from "../pages/dashboard/Leaderboard-review";

const routes = () => {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}></Suspense>
      {/* <Routes>
      <Route path="/" element={<PrivateRoute />}>
        <Route  path="/" element={<Dashboard />} />
        <Route  path="/faculty" element={<Faculty />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes> */}
      <Routes>
        <Route
          path="/"
          element={
            <ProtectRoute>
              <Dashboard />
            </ProtectRoute>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/faculty" element={<Faculty />} />
          <Route path="/students" element={<Students />} />
          <Route path="/students/analysis" element={<StudentAnalysis />} />
          <Route path="/leaderboard" element={<Leaderboard />}/>
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/leaderboard/:id" element={<LeaderboardResult/>}/>
          <Route path="/leaderboard/leaderboard-review" element={<LeaderboardReview/>}/>
          <Route path="/quiz" element={<Quiz />}>
            <Route path="/quiz/list" element={<QuizList/>}/>
            <Route path="/quiz/post" element={<QuizPost/>}/>
            <Route path="/quiz/list/quizquestions" element={<QuizQuestions/>}/>
            <Route path="/quiz/create" element={<QuizCreate/>}/>
            <Route path="/quiz/upload" element={<QuizUpload/>}/>
            <Route path="/quiz/list/customselect" element={<SelectCustomQuestions />}/>
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
};

export default routes;
