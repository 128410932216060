import { Select, Input, Form, Button, Row, Col, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import "../../styles/Quizcreate.css";
import { createQuiz } from "../../store/services/quizApi";
import { useSelector } from "react-redux";
import { getSubjectOptions } from "../../store/services/quizApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import QuizSystemGenerate from "./QuizSystemGenerate";
import QuizCustomTest from "./QuizCustomTest";

const QuizCreate = () => {
  const [form] = Form.useForm();

  const [step, setStep] = useState(1);

  const [subjectsListOptions, setSubjectListOptions] = useState();

  const [subjectsList, setSubjectList] = useState();

  // const [subjectDict, setSubjectDict] = useState();

  const [quizDetails, setQuizDetails] = useState();

  const [quizType, setQuizType] = useState("System Generated");

  const authenticate = useSelector((state) => state.authencation);

  const navigate = useNavigate();

  const handleQuizType = (type) => {
    setQuizType(type);
  };

  const getRandomEqual = (value, parts) => {
    const number = value;
    const numberOfParts = parts;
    const equalPart = Math.floor(number / numberOfParts);
    var remaining = number % numberOfParts;

    var parts = [];

    // Add the equal parts
    for (var i = 0; i < numberOfParts; i++) {
      parts.push(equalPart);
    }

    // Distribute the remaining value randomly
    for (var i = 0; i < remaining; i++) {
      var randomIndex = Math.floor(Math.random() * numberOfParts);
      parts[randomIndex]++;
    }

    return parts;
  };

  const handleNoOfQuestions = (e) => {
    let parts = getRandomEqual(e.target.value, 3);
    form.setFieldsValue({
      simpleCount: parts[0],
      mediumCount: parts[1],
      complexCount: parts[2],
    });
  };

  const handlequiz = (values) => {
    setStep(2);
    setQuizDetails(values);
    let total = getRandomEqual(values?.noOfQuestions, values.subjects.length);

    const updatedSubjects = subjectsList.map((subj, index) => {
      const parts = getRandomEqual(total[index], 3);
      return {
        ...subj,
        totalNoOfQuestions: total[index],
        simpleCount: parts[0],
        mediumCount: parts[1],
        complexCount: parts[2],
      };
    });

    setSubjectList(updatedSubjects);
  };

  const handleSubmitQuiz = () => {
    let body = {
      facultyId: authenticate?.profileInfo?.facultyId,
      quizName: quizDetails?.quizName,
      totalNoOfQuestions: parseInt(quizDetails?.noOfQuestions),
      simpleCount: parseInt(quizDetails?.simpleCount),
      mediumCount: parseInt(quizDetails?.mediumCount),
      complexCount: parseInt(quizDetails?.complexCount),
      quizSubject: subjectsList,
    };

    createQuiz(body).then((res) => {
      if (res?.quizId) {
        navigate("/quiz/list/quizquestions", {
          state: { quizId: res?.quizId },
        });
      }
    });
  };

  const selectedSubjects = (e) => {
    let subjects = subjectsListOptions.filter((item) =>
      e.includes(item.subjectId)
    );
   
    setSubjectList(subjects);
    if (step == 2) {
      form.submit();
    }
  };

  useEffect(() => {
    if (authenticate?.isAuthenticated) {
      getSubjectOptions({
        facultyId: authenticate?.profileInfo?.facultyId,
      }).then((res) => {
        setSubjectListOptions(res?.collegeFacultySubject);
      });
    }
  }, [authenticate]);

  const handleSubjectNumbers = (e, name, key) => {
    setSubjectList((prevData) => {
      return prevData.map((item) => {
        if (item.name === name) {
          return { ...item, [key]: parseInt(e.target.value || 0) };
        }
        return item;
      });
    });
  };

  return (
    <div className="p-4 pl-5">
      <div className="d-flex">
        <div className="mr-4">
          <p>
            <b>Quiz Create</b>
          </p>
        </div>
        <div>
          <Checkbox
            onChange={() => handleQuizType("System Generated")}
            checked={quizType == "System Generated" ? true : false}
          >
            System Generated
          </Checkbox>
        </div>
        <div className="mr-4">
          <Checkbox
            onChange={() => handleQuizType("Custom test")}
            checked={quizType == "Custom test" ? true : false}
          >
            Custom test
          </Checkbox>
        </div>
      </div>
      {quizType == "System Generated" ? (
        <QuizSystemGenerate />
      ) : (
        <QuizCustomTest />
      )}
    </div>
  );
};

export default QuizCreate;
