import { axiosRequestSilentPublic } from "../api-v1";
import { toast } from "react-toastify";

export const getFaculty = async (body) => {
    try {
      const res = await axiosRequestSilentPublic(
        "POST",
        "/lmsv2",
        "/getCollegeFaculty",
        body,
        {}
      );
      if (res.data.gatewayResponse.status.isSuccess) {
        return res.data.gatewayResponse.response;
      } else {
        toast.error(res.data.gatewayResponse.status.message);
        return res.data.gatewayResponse;
      }
    } catch (error) {
      throw new Error("Could not get student details");
    }
  };

export const addFaculty = async (body) => {
    try {
      const res = await axiosRequestSilentPublic(
        "POST",
        "/lmsv2",
        "/createCollegeFaculty",
        body,
        {}
      );
      if (res.data.gatewayResponse.status.isSuccess) {
        return res.data.gatewayResponse.response;
      } else {
        toast.error(res.data.gatewayResponse.status.message);
        return res.data.gatewayResponse;
      }
    } catch (error) {
      throw new Error("Could not create faculty");
    }
  };

  export const updateFaculty = async (body) => {
    try {
      const res = await axiosRequestSilentPublic(
        "POST",
        "/lmsv2",
        "/updateCollegeFaculty",
        body,
        {}
      );
      if (res.data.gatewayResponse.status.isSuccess) {
        return res.data.gatewayResponse.response;
      } else {
        toast.error(res.data.gatewayResponse.status.message);
        return res.data.gatewayResponse;
      }
    } catch (error) {
      throw new Error("Could not Update faculty");
    }
  };


export const deleteFaculty = async (body) => {
    try {
      const res = await axiosRequestSilentPublic(
        "POST",
        "/lmsv2",
        "/deleteCollegeFaculty",
        body,
        {}
      );
      if (res.data.gatewayResponse.status.isSuccess) {
        return res.data.gatewayResponse.response;
      } else {
        toast.error(res.data.gatewayResponse.status.message);
        return res.data.gatewayResponse;
      }
    } catch (error) {
      throw new Error("Could not delete faculty");
    }
  };


  const makeApiRequest = async (method, url, endpoint, body) => {
    try {
      const res = await axiosRequestSilentPublic(method, url, endpoint, body, {});
      if (res.data.gatewayResponse.status.isSuccess) {
        return res.data.gatewayResponse.response;
      } else {
        toast.error(res.data.gatewayResponse.status.message);
        return res.data.gatewayResponse;
      }
    } catch (error) {
      throw new Error(`API Request Error: ${error.message}`);
    }
  };

  export const getAllCourse = async (body) => {
    return makeApiRequest("POST", "/lmsv2", "/getAllCourse", body);
  };

  export const getSubjects = async (body) => {
    return makeApiRequest("POST", "/lmsv2", "/getSubjectsInCourse", body);
  };

export const AddSubjectToFaculty = async (body) => {
    return makeApiRequest("POST", "/lmsv2", "/createCollegeFacultySubject", body);
  };

  export const getFacultySubject = async (body) => {
    return makeApiRequest("POST", "/lmsv2", "/getCollegeFacultySubject", body);
  };

  export const deleteFacultySubject = async (body) => {
    return makeApiRequest("POST", "/lmsv2", "/deleteCollegeFacultySubject", body);
  };