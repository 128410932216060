import { Select, Input, Form, Button, Row, Col, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import "../../styles/Quizcreate.css";
import {
  createQuiz,
  getTopicsInSubject,
  getModulesInTopic,
} from "../../store/services/quizApi";
import { useSelector } from "react-redux";
import { getSubjectOptions } from "../../store/services/quizApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const QuizSystemGenerate = () => {
  const [form] = Form.useForm();

  const [step, setStep] = useState(1);

  const [subjectsListOptions, setSubjectListOptions] = useState();

  const [subjectsList, setSubjectList] = useState();

  const [SelectedSubjects, setSelectedSubject] = useState();

  // const [subjectDict, setSubjectDict] = useState();

  const [quizDetails, setQuizDetails] = useState();

  const authenticate = useSelector((state) => state.authencation);

  const navigate = useNavigate();

  const getRandomEqual = (value, parts) => {
    const number = value;
    const numberOfParts = parts;
    const equalPart = Math.floor(number / numberOfParts);
    var remaining = number % numberOfParts;

    var parts = [];

    // Add the equal parts
    for (var i = 0; i < numberOfParts; i++) {
      parts.push(equalPart);
    }

    // Distribute the remaining value randomly
    for (var i = 0; i < remaining; i++) {
      var randomIndex = Math.floor(Math.random() * numberOfParts);
      parts[randomIndex]++;
    }

    return parts;
  };

  const handleNoOfQuestions = (e) => {
    let parts = getRandomEqual(e.target.value, 3);
    form.setFieldsValue({
      simpleCount: parts[0],
      mediumCount: parts[1],
      complexCount: parts[2],
    });
  };

  const handlequiz = (values) => {
    if (step == 1) {
      setStep(2);
    }

    setQuizDetails(values);
    let total = getRandomEqual(values?.noOfQuestions, values.subjects.length);

    const updatedSubjects = SelectedSubjects.map((subj, index) => {
      const parts = getRandomEqual(total[index], 3);
      return {
        ...subj,
        totalNoOfQuestions: total[index],
        simpleCount: parts[0],
        mediumCount: parts[1],
        complexCount: parts[2],
      };
    });

    if (step == 1) {
      setSubjectList(updatedSubjects);
    } else if (step == 2) {
      const modifiedSubjects = updatedSubjects.map((subject) => {
        const matchingSubject = subjectsList.find(
          (item) => item.subjectId === subject.subjectId
        );

        if (matchingSubject) {
          return { ...subject, moduleList: matchingSubject.moduleList };
        } else {
          return subject;
        }
      });

      setSubjectList(modifiedSubjects);
    }
  };

  const handleSubmitQuiz = () => {
    for (const obj of subjectsList) {
      if (!obj.moduleList || obj.moduleList.length < 1) {
        alert(`Please select modules for ${obj.name} subject`);
        return null;
      }
    }

    let body = {
      facultyId: authenticate?.profileInfo?.facultyId,
      quizName: quizDetails?.quizName,
      totalNoOfQuestions: parseInt(quizDetails?.noOfQuestions),
      simpleCount: parseInt(quizDetails?.simpleCount),
      mediumCount: parseInt(quizDetails?.mediumCount),
      complexCount: parseInt(quizDetails?.complexCount),
      quizSubject: subjectsList,
    };

    // console.log(body)

    createQuiz(body).then((res) => {
      if (res?.quizId) {
        navigate("/quiz/list/quizquestions", {
          state: { quizId: res?.quizId },
        });
      }
    });
  };

  const selectedSubjects = (e) => {
    let subjects = subjectsListOptions.filter((item) =>
      e.includes(item.subjectId)
    );

    setSelectedSubject(subjects);

    // if (step == 1) {
    //   setSubjectList(subjects);
    // }

    // if (subjects.length == 0) {
    //   setStep(1);
    // } else if (step == 2) {
    //   const modifiedSubjects = subjects.map((subject) => {
    //     const matchingSubject = subjectsList.find(
    //       (item) => item.subjectId === subject.subjectId
    //     );

    //     if (matchingSubject) {
    //       return { ...subject, moduleList: matchingSubject.moduleList };
    //     } else {
    //       return subject;
    //     }
    //   });

    //   setSubjectList(modifiedSubjects);

    //   form.submit();
    // }
  };

  useEffect(() => {
    if (authenticate?.isAuthenticated) {
      getSubjectOptions({
        facultyId: authenticate?.profileInfo?.facultyId,
      }).then((res) => {
        setSubjectListOptions(res?.collegeFacultySubject);
      });
    }
  }, [authenticate]);

  useEffect(() => {
    console.log(subjectsList);
  }, [subjectsList]);

  const handleSubjectNumbers = (e, name, key) => {
    if (key == "moduleList") {
      let modules = e.map((number) => ({
        moduleId: number,
      }));

      setSubjectList((prevData) => {
        return prevData.map((item) => {
          if (item.name === name) {
            return { ...item, [key]: modules };
          }
          return item;
        });
      });
    } else {
      setSubjectList((prevData) => {
        return prevData.map((item) => {
          if (item.name === name) {
            return { ...item, [key]: parseInt(e.target.value || 0) };
          }
          return item;
        });
      });
    }
  };

  return (
    <>
      <div className="shadow p-2 quiz-card">
        <Form
          form={form}
          colon={false}
          layout="vertical"
          onFinish={handlequiz}
          style={{
            border: "2px solid lightgrey",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Quiz name"
                name="quizName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Total No of Question"
                name="noOfQuestions"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Enter Count"
                  onChange={(e) => handleNoOfQuestions(e)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Select Subjects"
                name="subjects"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="select any"
                  onChange={selectedSubjects}
                >
                  {subjectsListOptions?.map((item) => {
                    return (
                      <Select.Option value={item?.subjectId}>
                        {item?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ display: "none" }}>
            <Col span={8}>
              <Form.Item
                label="Simple count"
                name="simpleCount"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Enter Count" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Medium count"
                name="mediumCount"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Enter Count" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Complex count"
                name="complexCount"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Enter Count" />
              </Form.Item>
            </Col>
          </Row>

          <div className="d-flex">
            <Button htmlType="submit" style={{ marginLeft: "auto" }}>
              Next
            </Button>
          </div>
        </Form>
      </div>

      <>
        {step == 2 ? (
          <div className="shadow p-2 pb-4 quiz-card">
            {/* <table className="quiz-subjects-table"> */}
            {/* <tr> */}
            {/* <th>Subject</th>
                <th>Questions</th>
                <th>Select Topics</th>
                <th>Select Modules</th> */}
            {/* <th>Simple</th>
                <th>Medium</th>
                <th>Complex</th> */}
            {/* </tr> */}
            {subjectsList?.map((item, index) => {
              return (
                <SysSubjectSelection
                  key={item?.subjectId}
                  item={item}
                  handleSubjectNumbers={handleSubjectNumbers}
                />
              );
            })}
            {/* </table> */}
            <div className="d-flex mt-2">
              <Button
                onClick={() => handleSubmitQuiz()}
                style={{ marginLeft: "auto" }}
                // disabled = {}
              >
                Save & Next
              </Button>
            </div>
          </div>
        ) : null}
      </>
    </>
  );
};

export default QuizSystemGenerate;

const SysSubjectSelection = ({ item, handleSubjectNumbers }) => {
  const [topicOptions, setTopicOptions] = useState([]);

  const [moduleOptions, setModuleOptions] = useState([]);

  const [selectedModules, setSelectedModules] = useState([]);

  const fetchTopics = () => {
    getTopicsInSubject({
      subjectId: item?.subjectId,
    }).then((res) => {
      setTopicOptions(res?.topic);
    });
  };

  useEffect(() => {
    fetchTopics();
  }, [item]);

  const handleChange = (field, value) => {
    if (field === "topics" && value !== null) {
      const promises = value.map((value) =>
        getModulesInTopic({ topicId: value })
      );

      Promise.all(promises).then((responses) => {
        console.log(responses);
        const combinedModules = responses.reduce((acc, res) => {
          if (res && res.module && Array.isArray(res.module)) {
            acc.push(...res.module);
          }
          return acc;
        }, []);

        console.log(combinedModules);
        setModuleOptions(combinedModules);

        const filteredmodules = selectedModules.filter((value) => {
          return combinedModules.some((obj) => obj.moduleId === value);
        });
        setSelectedModules(filteredmodules);
      });

      // getModulesInTopic({
      //   topicId: value,
      // }).then((res) => {
      //   console.log(res?.module);
      //   setModuleOptions(res?.module);

      // });
    } else if (field === "module" && value !== null) {
    }
  };

  const handleModules = (e, name, key) => {
    setSelectedModules(e);
    handleSubjectNumbers(e, name, key);
  };

  return (
    <div
      className="mb-4 p-2"
      style={{ border: "2px solid lightgrey", borderRadius: "10px" }}
    >
      <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
        <div>
          <label>Subject Name: </label> <b>{item?.name}</b>
        </div>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ gap: "10px" }}
        >
          <label className="m-0">No of Questions:</label>

          <Input
            name={item?.name + "_total"}
            value={item?.totalNoOfQuestions}
            style={{ width: "100px" }}
            onChange={(value) =>
              handleSubjectNumbers(value, item?.name, "totalNoOfQuestions")
            }
          />
        </div>
      </div>
      <div className="mb-3">
        <label>
          <b>Select Topics</b>
        </label>
      </div>
      <div className="mb-3">
        <Select
          mode="multiple"
          placeholder="Select Topics"
          style={{ width: "100%" }}
          onChange={(value) => handleChange("topics", value)}
        >
          {topicOptions?.map((item) => {
            return (
              <Select.Option value={item?.topicId}>{item?.name}</Select.Option>
            );
          })}
        </Select>
      </div>
      <div className="mb-3">
        <label>
          <b>Select Modules</b>
        </label>
      </div>
      <div className="mb-3">
        <Select
          mode="multiple"
          placeholder="Select Module"
          style={{ width: "100%" }}
          value={selectedModules}
          onChange={(value) => handleModules(value, item?.name, "moduleList")}
        >
          {moduleOptions?.map((item) => {
            return (
              <Select.Option value={item?.moduleId}>{item?.name}</Select.Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

// <tr>
//   <td>{item?.name}</td>
//   <td>
//     <Input
//       name={item?.name + "_total"}
//       value={item?.totalNoOfQuestions}
//       onChange={(e) =>
//         handleSubjectNumbers(
//           e,
//           item?.name,
//           "totalNoOfQuestions"
//         )
//       }
//     />
//   </td>

{
  /* <td>
                      <Input
                        name={item?.name + "_simple"}
                        value={item?.simpleCount}
                        onChange={(e) =>
                          handleSubjectNumbers(e, item?.name, "simpleCount")
                        }
                      />
                    </td>
                    <td>
                      <Input
                        name={item?.name + "_medium"}
                        value={item?.mediumCount}
                        onChange={(e) =>
                          handleSubjectNumbers(e, item?.name, "mediumCount")
                        }
                      />
                    </td>
                    <td>
                      <Input
                        name={item?.name + "_complex"}
                        value={item?.complexCount}
                        onChange={(e) =>
                          handleSubjectNumbers(e, item?.name, "complexCount")
                        }
                      />
                    </td> */
}
// </tr>
