import React, { useEffect } from "react";
import "../styles/Header.css";
import Logo from "../assests/logo.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Space } from "antd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faBell, faUser, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { logout } from "../store/authSlice/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { Home_API_SUCCESS } from "../store/HomeSlice";
import { axiosRequestSilentPublic } from "../store/api-v1";

const Header = () => {
  const dispatch = useDispatch()
  const authencation = useSelector((state)=> state.authencation)

  const fetchimages = async (id) => {
    const results = await axiosRequestSilentPublic("POST", "/lmsv2", "/getCollegeHomePageApiInfo", {
      "collegeId" : id
      }, {});
    if (results.data.gatewayResponse.status.isSuccess) {
          return results.data.gatewayResponse.response
    }
    return results.data
  }
  const items = [
    {
      key: "1",
      label: <Link to="/profile">Profile</Link>,
    },
    {
      key: "3",
      label: <div onClick={()=> { dispatch(logout());localStorage.clear()}}>Log Out</div>,
    },
  ];

  useEffect(()=>{
    if (authencation.isAuthenticated){
      fetchimages(authencation.profileInfo?.collegeId).then((res)=>{
        dispatch(Home_API_SUCCESS(res.CollegeHomePageApiInfo))
        
      })
    }

  },[authencation])
  return (
    <div className="Header-wrapper">
      
      <div className="Header-component">
        <div className="header-logo">
          <Link to="/">
            <img src={authencation?.profileInfo?.homePageCollegelogo} alt="Logo" />
          </Link>
        </div>
        <div className="Header-action-box">
          <div className="search-container d-none">
            <div>
              <FontAwesomeIcon icon={faSearch}  />
            </div>
            <div>
              <input placeholder="Type to search" />
            </div>
          </div>
          {/* <div style={{background: '#FAFAFA', padding: '5px 10px', borderRadius: '10px'}}><FontAwesomeIcon icon={faBell}  /></div> */}
          <div style={{background: '#FAFAFA', padding: '5px 10px', borderRadius: '10px'}}>
            <Dropdown
              menu={{
                items,
              }}
            >
              <a onClick={(e) => e.preventDefault()} >
                <Space>
                <FontAwesomeIcon icon={faUser}  /><FontAwesomeIcon icon={faAngleDown}  />
                  {/* <DownOutlined /> */}
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
