import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import { useHistory } from 'react-router-dom';
import { useLocation, useParams } from "react-router-dom";
import { getQuizResult, downloadResults } from "../../store/services/quizApi";
import "../../styles/leader-board-result.css";
import { useSelector } from "react-redux";
import { Button } from "antd";
import axios from "axios";
import { BASE_URL } from "../../store/api-v1";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export const LeaderBoardResult = (props) => {
  const authState = useSelector((store) => store.authencation);
  const [isLoading, setLoading] = useState(true);
  const params = useParams();
  // const { fetchquestionBanks, authState, qstnState } = props;
  const [qstnState, setqstnState] = useState();
  const location = useLocation();
  // const history = useHistory();
  const quizId = location?.state?.quizId;
  const [firstRank, setFirstRank] = useState([]);
  const [secondRank, setSecondRank] = useState([]);
  const [thirdRank, setThirdRank] = useState([]);
  const [otherRank, setOtherRank] = useState([]);

  useEffect(() => {
    const first = qstnState?.rankers?.filter((data) => data.rank === 1);
    setFirstRank(first);
    const second = qstnState?.rankers?.filter((data) => data.rank === 2);
    setSecondRank(second);
    const third = qstnState?.rankers?.filter((data) => data.rank === 3);
    setThirdRank(third);
    const other = qstnState?.rankers?.filter((data) => data.rank > 0);
    setOtherRank(other);
  }, [qstnState?.rankers]);

  const fetchResultData = async () => {
    const body = {
      // studentId:79302,
      // studentId: authState?.profileInfo.studentId,
      quizId: params?.id,
    };
    setLoading(true);
    await getQuizResult(body).then((res) => setqstnState(res));
    setLoading(false);
  };

  const handleResultsDownload = async () => {
    const headers = {
      apiversion: "1.0.0.0",
      appversion: "1.0.0.0",
      channelid: "Web",
      "Content-Type": "application/json",
      SerreqId: "/getQuizRankersListDownloadProd",
    };

    let Api_URL = `${BASE_URL}${"/lmsv2"}`;

    axios
      .post(
        Api_URL,
        {
          quizID: params?.id,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data?.status == "Y") {
          const fileLink = res?.data?.message;

          const link = document.createElement("a");
          link.href = fileLink;
          link.setAttribute("download", "filename.ext");
          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
          toast.success("Download complete");
        } else {
          toast.error(res.data?.message);
        }
        // window.URL.revokeObjectURL(fileURL);

        // const fileResponse = axios
        //   .get(fileLink, {
        //     responseType: "blob",
        //   })
        //   .then((response) => {
        //     console.log(response);
        //     const fileURL = window.URL.createObjectURL(
        //       new Blob([response.data])
        //     );

        //     const link = document.createElement("a");
        //     link.href = fileURL;
        //     link.setAttribute("download", "filename.ext");
        //     document.body.appendChild(link);

        //     link.click();

        //     link.parentNode.removeChild(link);
        //     window.URL.revokeObjectURL(fileURL);
        //   });
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      fetchResultData();
    }
  }, [authState?.isAuthenticated]);

  // const handleFeedback = () => {
  //     history.replace({
  //         pathname: '/dashboard/leaderboard-feedback',
  //         state: {
  //             ...(history.location.state || {}),
  //             correctCount:qstnState?.QBInfo?.myCorrect,
  //             incorrectCount: qstnState?.QBInfo?.myWrong,
  //             skippedCount:qstnState?.QBInfo?.mySkipped,
  //             assessmentId: qstnState?.QBInfo?.myAssessmentId,
  //         }
  //     })
  // }
  // const handleReview = () => {
  //     history.push({
  //         pathname: '/dashboard/leaderboard-review',
  //         state: {
  //             ...(history.location.state || {}),
  //             correctCount:qstnState?.QBInfo?.myCorrect,
  //             incorrectCount: qstnState?.QBInfo?.myWrong,
  //             skippedCount:qstnState?.QBInfo?.mySkipped,
  //             assessmentId: qstnState?.QBInfo?.myAssessmentId,
  //         }
  //     })
  // }

  return (
    <div className="leaderBoardResultWrapper">
      {/* <h6>Result</h6> */}
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2>Result</h2>
        <Button
          style={{ background: "orange", color: "#fff" }}
          onClick={() => {
            handleResultsDownload();
          }}
        >
          Download Results
        </Button>
      </div>
      <div className="row">
        <div className="col-xl-6 col-12">
          <div className="scoreBoard">
            <div className="scoreBoardBox">
              {secondRank?.length > 0 &&
                secondRank?.map((item) => {
                  return <h5>{item.name}</h5>;
                })}
              <h6>Score</h6>
              <p>{secondRank && secondRank[0]?.score}</p>
            </div>
            <div className="scoreBoardBox">
              {firstRank?.length > 0 &&
                firstRank?.map((item) => {
                  return <h5>{item.name}</h5>;
                })}
              <h6>Score</h6>
              <p>{firstRank && firstRank[0]?.score}</p>
            </div>
            <div className="scoreBoardBox">
              {thirdRank?.length > 0 &&
                thirdRank?.map((item) => {
                  return <h5>{item.name}</h5>;
                })}
              <h6>Score</h6>
              <p>{thirdRank && thirdRank[0]?.score}</p>
            </div>
          </div>
          <div className="scoreNumber">
            <div className="scoreNumberBox">2</div>
            <div className="scoreNumberBox">1</div>
            <div className="scoreNumberBox">3</div>
          </div>
          {/* <div className='rankBlock'>
                        <div className='rankRow'>
                            <div className="rankScore">
                                <h4>Your Rank-</h4>
                                <h5>{qstnState?.QBInfo?.myRank}</h5>
                            </div>
                            <button className="rankbtn" onClick={handleReview}>Review Quiz</button>
                        </div>
                        <div className='rankRow'>
                            <div className="rankScore">
                                <h4>Your Score-</h4>
                                <h5>{qstnState?.QBInfo?.myScore}</h5>
                            </div>
                            <button className="rankbtn" onClick={handleFeedback}>Feedback</button>
                        </div>
                        <div className='countbox'>
                            <div className='count bg-success'>
                                <div>
                                {qstnState?.QBInfo?.myCorrect}
                                </div>
                                <div>
                               Right Answer
                                </div>
                            </div>
                            <div className='count bg-danger'>
                                <div>
                                {qstnState?.QBInfo?.myWrong}
                                </div>
                                <div>
                                    Wrong Answer
                                </div>
                            </div>
                            <div className='count bg-secondary'>
                                <div>
                                {qstnState?.QBInfo?.mySkipped}
                                </div>
                                <div>
                                   Skipped Questions 
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
        <div className="col-xl-6 col-12">
          <div className="tableRank">
            <div className="tableHeading">
              <div className="headname">
                <span>Rank</span>
                <span>Name</span>
                <span>Roll No</span>
                <span>Score</span>
                <span>Review</span>
                
              </div>
              
            </div>
            <div className="rank-table-content">
              {otherRank?.length > 0 &&
                otherRank?.map((item) => {
                  return (
                    <div className="tableBody">
                      <div className="bodyname">
                        <span className="rankNumber">{item.rank}</span>
                        <span className="rankName">{item.name}</span>
                        <span >{item.rollNo}</span>
                        <span>{item.score}</span>
                        <span><Link to={`/leaderboard/leaderboard-review`} state={{ studentId: item?.studentId, quizId : params?.id, assessmentId: item?.assessmentId}}>Review</Link></span>
                        
                      </div>
                      
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardResult;
