import React from "react";
import { Select, Input, Form, Button, Row, Col } from "antd";

const QuizUpload = () => {
  const [form] = Form.useForm();
  return (
    <div className="p-4 pt-5">
      <div
        style={{ background: "white", width: "700px", marginBottom: "10px" }}
        className="shadow p-3"
      >
        <Form form={form} colon={false} layout="vertical">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Quiz name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Total No of Question">
                <Select placeholder="select" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Select Subjects">
                <Select mode="multiple" placeholder="select any">
                  <Select.Option>Anatomy</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Button htmlType="submit">Submit</Button>
        </Form>
      </div>
    </div>
  );
};

export default QuizUpload;
