import React, { useEffect, useState } from "react";
import { Form, Input, Select, Row, Col, Space, Button } from "antd";
import { profileAPI } from "../../store/authSlice/api";
import { useSelector } from "react-redux";
import "../../styles/profile.css";

const Profile = () => {
  const [form] = Form.useForm();

  const authenticate = useSelector((state) => state.authencation);

  const [profileData, setProfileData] = useState();

  useEffect(() => {
    if (authenticate.isAuthenticated) {
      profileAPI({
        facultyId: authenticate.profileInfo.facultyId,
      }).then((res) => {
        if (res?.collegeFaculty) {
          setProfileData(res?.collegeFaculty[0]);
        }
      });
    }
  }, [authenticate]);

  useEffect(() => {
    form.setFieldsValue({
      facultyId: profileData?.facultyId,
      // name: profileData?.name,
      mobile: profileData?.mobileNo,
      email: profileData?.email,
      userType: profileData?.userType,
      education: profileData?.education,
      subjects: profileData?.subjects,
    });
  }, [profileData]);

  return (
    <div className="pt-5 pl-5">
      <p>
        <b>Profile</b>
      </p>
      <div className="d-flex flex-column">
        {/* <div className="d-flex" style={{justifyContent: 'start',alignItems: 'center'}}><div style={{minWidth: '10%'}}>Faculty Id</div><div style={{width: '70%'}}><Input value={profileData?.facultyId} className="profile-input" /></div></div> */}
        {/* <div>College Admin: </div>
        <Input value={profileData?.facultyId} className="profile-input" />
        <Input value={profileData?.name} className="profile-input" />
        <Input value={profileData?.mobileNo} className="profile-input" />
        <Input value={profileData?.subjects} className="profile-input" />
        <Select className="profile-select" value={profileData?.userType}>
          <Select.Option value="Faculty">Faculty</Select.Option>
          <Select.Option value="HOD">HOD</Select.Option>
          <Select.Option value="Principal">Prinicipal</Select.Option>
        </Select>
        <Select className="profile-select" value={profileData?.education}>
          <Select.Option value="PG">PG</Select.Option>
          <Select.Option value="MBBS">MBBS</Select.Option>
          <Select.Option value="MD">MD</Select.Option>
          <Select.Option value="MS">MS</Select.Option>
        </Select> */}

        {/* <Form
                colon={false}
                layout="horizontal"
                
                form={form}
              >
                <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                      label="Id"
                      name="facultyId"
                      style={{ fontWeight: "bold" }}
                      
                    >
                      <Input
                        style={{ background: "#D0E7F3", height: "40px",color: 'black' }} value={profileData?.name}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Name"
                      name="name"
                      style={{ fontWeight: "bold" }}
                    >
                      <Input
                        style={{ background: "#D0E7F3", height: "40px",color: 'black' }} disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Mobile"
                      name="mobile"
                      style={{ fontWeight: "bold" }}
                    >
                      <Input
                        style={{ background: "#D0E7F3", height: "40px",color: 'black' }} disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Email"
                      name="email"
                      style={{ fontWeight: "bold" }}
                    >
                      <Input
                        style={{ background: "#D0E7F3", height: "40px",color: 'black' }} disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="User Type"
                      name="userType"
                      style={{ fontWeight: "bold" }}
                    >
                      <Select style={{ background: "#D0E7F3", height: "40px", borderRadius: '5px', color: 'black' }} disabled>
                        <Select.Option value="Faculty">Faculty</Select.Option>
                        <Select.Option value="HOD">HOD</Select.Option>
                        <Select.Option value="Principal">
                          Prinicipal
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Education"
                      name="education"
                      style={{ fontWeight: "bold" }}
                    >
                      <Select style={{ background: "#D0E7F3", height: "40px", borderRadius: '5px', }} disabled>
                        <Select.Option value="PG">PG</Select.Option>
                        <Select.Option value="MBBS">MBBS</Select.Option>
                        <Select.Option value="MD">MD</Select.Option>
                        <Select.Option value="MS">MS</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                    label = "Subjects"
                      name="subjects"
                      style={{ fontWeight: "bold" }}
                    >
                      <Input
                        style={{ background: "#D0E7F3", height: "40px",color: 'black' }} disabled
                      />
                    </Form.Item>
                  </Col>
                 
                    
                  
                </Row>
              </Form> */}

        <div className="row ">
          <div className="col-lg-8 col-xl-8 col-md-12 col-sm-12 col-12 d-flex flex-column profile-details">
            <div>College Admin: {profileData?.userType == "Admin"? "Yes": "No"} </div>
            <Input value={profileData?.facultyId} className="profile-input" />
            <Input value={profileData?.name} className="profile-input" />
            <Input value={profileData?.mobileNo} className="profile-input" />
            <Input value={profileData?.email} className="profile-input" />
            <Select className="profile-select" value={profileData?.userType}>
                <Select.Option value="HOD">HOD</Select.Option>
                <Select.Option value="Professor">Professor</Select.Option>
                <Select.Option value="Assistant Professor">Assistant Professor</Select.Option>
                <Select.Option value="Principal">Prinicipal</Select.Option>
                <Select.Option value="Dean">Dean</Select.Option>            
                <Select.Option value="Faculty">Faculty</Select.Option>
            </Select>
            <Select className="profile-select" value={profileData?.education}>
              <Select.Option value="PG">PG</Select.Option>
              {/* <Select.Option value="MBBS">MBBS</Select.Option>
              <Select.Option value="MD">MD</Select.Option>
              <Select.Option value="MS">MS</Select.Option> */}
            </Select>
            {
              profileData?.subjects !== ""?<Input value={profileData?.subjects} className="profile-input" />:null
            }
            
          </div>
          <div className="col d-flex justify-content-center">
            <div>
              <div className="mt-5">
                <div className="border border-secondary rounded p-2">
                  <img src={profileData?.logo} width={"80"} alt="profile" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
