import React from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Outlet } from "react-router-dom";
// import Home from "./Home";
// import Students from "./Students";
// import Faculty from "./Faculty";
// import Leaderboard from "./Leaderboard";
// import useResolvedPath from 'react-router-dom'


const dashboard = () => {

  // const { pathname } = useLocation();

  return (
    <>
      <div className="d-flex" style={{maxHeight: '100vh'}}>
        <Sidebar />
        <div className="d-flex flex-column gap-3" style={{ width: "85%",background: '#FAFAFA',minHeight:'100vh' }}>
          <Header />
          <div  className="content">
          {/* <Suspense fallback={<div>Loading...</div>}></Suspense>s */}
          {/* <Routes>
            <Route path="/dashboard" element={<Home/>} />
            <Route path="/dashboard/students" element={<Students/>} />
            <Route path="/dashboard/faculty" element={<Faculty/>} />
            <Route path="/dashboard/leaderboard" element={<Leaderboard/>} />
          </Routes> */}
          <Outlet/>

          </div>
          
        </div>
      </div>
    </>
  );
};

export default dashboard;
