import Axios from "axios";
import { toast } from "react-toastify";
import cookies from 'react-cookies'
import { axiosRequestSilent, axiosRequestSilentPublic, axiosRequestNA } from "../api-v1";
import { AUTHENTICATING_USER_ERROR,UPDATE_USER,AUTHENTICATING_USER_SUCCESS,AUTHENTICATING_USER} from './loginSlice'
import { useDispatch } from "react-redux";

// const dispatch = useDispatch()

// export const AUTHENTICATING_USER = "AUTHENTICATING_USER";
// export const AUTHENTICATING_USER_SUCCESS = "AUTHENTICATING_USER_SUCCESS";
// export const AUTHENTICATING_USER_ERROR = "AUTHENTICATING_USER_ERROR";
// export const LOGOUT_USER = "LOGOUT_USER";
// export const UPDATE_USER = 'UPDATE_USER';


// export const login = (credentials) => async (dispatch) => {
//     dispatch({ type: "AUTHENTICATING_USER" });
  
//     try {
//       // Make API call to authenticate user
//       const response = await axios.post(credentials);
  
//       // Handle success response
//       dispatch({ type: "AUTHENTICATING_USER_SUCCESS" });
//       dispatch({ type: "UPDATE_USER", payload: response.data });
//     } catch (error) {
//       // Handle error response
//       dispatch({ type: "AUTHENTICATING_USER_ERROR", payload: error.message });
//     }
//   };
export const profileAPI = async (body) => {
    try {
        const res = await axiosRequestSilentPublic("POST", "/lmsv2", "/getCollegeFaculty", body, {});
        if (res.data.gatewayResponse.status.isSuccess) {
            return res.data.gatewayResponse.response;
        } else {
            toast.error(res.data.gatewayResponse.status.message);
            return res.data.gatewayResponse;
        }
    } catch (error) {
        throw new Error("Could not get profile details");
    }
}

export const sendOTP = async (body) => {
    try {
        const res = await axiosRequestSilentPublic("POST", "/lmsv2", "/sendOtp", body, {});
        if (res.data.gatewayResponse.status.isSuccess) {
            toast.success("otp sent successfully");
            return res.data.gatewayResponse;
        } else {
            toast.error(res.data.gatewayResponse.status.message);
            return res.data.gatewayResponse;
        }
    } catch (error) {
        throw new Error("Could not send Otp");
    }
}


export const VerifyOTPLogin = async (otpBody, callback) =>  {

    // const dispatch = useDispatch()
    
    // dispatch(AUTHENTICATING_USER());
    try {
        const res = await axiosRequestNA("POST", "/lmsv2", "/verifyOtp", otpBody, {});
        if (res.data.gatewayResponse.response) {
            // dispatch(AUTHENTICATING_USER_SUCCESS(res.data.gatewayResponse))
            // const sessionToken = res.data.gatewayResponse.response.sessionToken;
            // const refreshToken = res.data.gatewayResponse.response.refreshToken;
            // const studentId = res.data.gatewayResponse.response.studentId;
            // cookies.save('studentId', studentId);
            // cookies.save('sessionToken', sessionToken);
            // cookies.save('refreshToken', refreshToken);
            // const localStoragedata = {
            //     studentId: studentId,
            //     sessionToken: sessionToken,
            //     refreshToken: refreshToken,
            //     loginpath: '/dashboard'
            // }
            localStorage.setItem('localStoragedata', JSON.stringify(res.data.gatewayResponse.response));
            return res.data.gatewayResponse.response;
            
        } else {
            // dispatch({ type: AUTHENTICATING_USER_ERROR, res: res.data.gatewayResponse.status.message });
        }
    } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
            // dispatch({ type: AUTHENTICATING_USER_ERROR, errMsg: error?.response?.data });
            // return;
        }
        // dispatch({ type: AUTHENTICATING_USER_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }
}

export const tryToAuthenticate = () => async (dispatch) => {
    dispatch(AUTHENTICATING_USER())
    // var sessionToken = cookies.load("sessionToken");
    // var studentId = parseInt(cookies.load("studentId"));
    // var refreshToken = cookies.load("refreshToken");

    
    const tryToAuthenticate1 = JSON.parse(localStorage.getItem('localStoragedata'))
    //    console.log('tryToAuthenticate1 123' , tryToAuthenticate1)
    if (tryToAuthenticate1 !== null) {
        // sessionToken = tryToAuthenticate1.sessionToken;
        // studentId = parseInt(tryToAuthenticate1.studentId);
        // refreshToken = parseInt(tryToAuthenticate1.refreshToken);
        // cookies.save('studentId', studentId);
        // cookies.save('sessionToken', sessionToken);
        // cookies.save('refreshToken', refreshToken);
    }
    

    // if (!studentId || !sessionToken)return false;
    dispatch(AUTHENTICATING_USER_SUCCESS(tryToAuthenticate1));
    try {
        // const res = await axiosRequestSilent("POST", "/student", "/getStudentById", { studentId }, {});
        // if (res.data.gatewayResponse.status.isSuccess) {
        //     dispatch({ type: AUTHENTICATING_USER_SUCCESS, res: res.data.gatewayResponse.response })
        //     return true;
        // } else {
        //     dispatch({ type: AUTHENTICATING_USER_ERROR, res: res.data.gatewayResponse.status.message });
        //     return false;
        // }
    } catch (error) {
        // console.log(error);
        // if (error.response && error.response.data) {
        //     dispatch({ type: AUTHENTICATING_USER_ERROR, errMsg: error?.response?.data });
        //     return false;
        // }
        // dispatch({ type: AUTHENTICATING_USER_ERROR, errMsg: "Could not fetch post something went wrong !" });
    }

}