import React, { useEffect, useState } from "react";
import "../styles/Sidebar.css";
import Logo from "../assests/pngtree-scool-college-logo-victor-png-image_9026968.png";
import Home from "../assests/Home.png";
import Leaderboard from "../assests/leaderboard.png";
import Faculty from "../assests/faculty.png";
import Student from "../assests/student.png";
import Thinking from "../assests/thinking.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const [isopen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  const userType = useSelector((state) => state.authencation?.profileInfo?.userType);

  const dtLogo = useSelector((state) => state.authencation?.profileInfo?.homePageDTlogo);

  useEffect(()=>{
   if (pathname.includes("quiz")){
    setIsOpen(true)
   }else{
    setIsOpen(false)
   }
  },[pathname])
  // console.log(pathname)
  return (
    <div className="sidebar-component">
      <div className="sidebar-logo">
        <img src={dtLogo} alt="Logo" />
      </div>
      <div>
        <ul>
          <li className={pathname == "/" ? "sidebar-menu-item active-tab":"sidebar-menu-item"}>
            <Link to="/">
              <img src={Home} width="20" />
              Home
            </Link>
          </li>
          <li className={pathname.includes("/quiz") ? "sidebar-menu-item active-tab":"sidebar-menu-item"} onClick={() => setIsOpen(!isopen)}>
          <a role="button">
            <img src={Thinking} width="20" />
            Quiz
            <FontAwesomeIcon icon={faAngleDown} style={{marginLeft:'auto'}} rotation={isopen?180:0}/>
          </a>
          {/* <div> */}
          <ul style={{ display: isopen ? "block" : "none",background: '#CBEEF7', transition: '2s' }} className="sidebar-menu-item-subgroup">
              <li><Link to="/quiz/list" style={{color: pathname == "/quiz/list"? 'blue':'black'}}>List</Link></li>
              <li><Link to="/quiz/create" style={{color: pathname == "/quiz/create"? 'blue':'black'}}>Create</Link></li>
              {/* <li><Link to="/quiz/upload" style={{color: pathname == "/quiz/upload"? 'blue':'black'}}>Upload</Link></li> */}
            </ul>

          {/* </div> */}
            
          </li>
          <li className={pathname.includes("/leaderboard") ? "sidebar-menu-item active-tab":"sidebar-menu-item"}>
            <Link to="leaderboard">
              <img src={Leaderboard} width="20" />
              Leaderboard
            </Link>
          </li>
          { 
            userType == "Admin" ? <li className={pathname == "/faculty" ? "sidebar-menu-item active-tab":"sidebar-menu-item"}>
            <Link to="faculty">
              <img src={Faculty} width="20" />
              Faculty
            </Link>
          </li> : null 
          }
          
          <li className={pathname == "/students" ? "sidebar-menu-item active-tab":"sidebar-menu-item"} onClick={()=>{localStorage.removeItem("academicYearId")}}>
            <Link to="students">
              <img src={Student} width="20" />
              Students
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
