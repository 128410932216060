import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getQuizQuestions,
  replaceQuestion,
} from "../../store/services/quizApi";
import { Buffer } from "buffer";
import { useSelector } from "react-redux";
import "../../styles/quiz.css";
import { Button } from "antd";
import { Link } from "react-router-dom";

const QuizQuestions = () => {
  const authState = useSelector((store) => store.authencation);
  const [isLoading, setLoading] = useState(true);
  const [questions, setQuestions] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  const fetchQuestions = async () => {
    const body = {
      quizId: location?.state?.quizId,
    };
    setLoading(true);
    await getQuizQuestions(body).then((res) => {
      if (res?.data) {
        let responseData = Buffer.from(res?.data, "base64").toString();
        responseData = JSON.parse(responseData);
        responseData = responseData.map((item) => ({
          ...item,
          isExpanded: false,
        }));
        setQuestions(responseData);
      }
    });
    setLoading(false);
  };

  const handleReplace = (question) => {
    const body = {
      quizId: parseInt(location?.state?.quizId),
      subjectId: question?.subjectId,
      quizQuestionId: question?.quizQuestionId,
      questionType: question?.questionType,
    };

    replaceQuestion(body).then((res) => {
      const responseData = Buffer.from(res?.data, "base64").toString();
      setQuestions(JSON.parse(responseData));
    });
  };

  useState(() => {
    if (authState?.isAuthenticated) {
      fetchQuestions();
    }
  }, [authState]);

  const handleredirect = () => {
    navigate("/quiz/list");
  };

  const toggleExpansion = (index) => {
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question, i) => {
        if (i === index) {
          return {
            ...question,
            isExpanded: !question.isExpanded,
          };
        }
        return question;
      });
    });
  };

  return (
    <div className="p-4" id="questions-box" style={{position: 'relative'}}>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <Link to="/quiz/list" state={{ quiztype: "System Generate"  }}>Back to Quiz List</Link>
        
      </div>
      {/* <div className="d-flex flex-column" style={{height: '400px', overflow: 'auto'}}> */}
      {questions?.map((question, index) => {
        return (
          <div className="question-box shadow">
            <div className="question-box-item">
              <div className="item-1">
                <b>S/no:</b>
              </div>
              <div className="item-2">{index + 1}</div>
            </div>
            {/* <div className="question-box-item">
              <div className="item-1">
                <b>Question Type:</b>
              </div>
              <div className="item-2">{question?.questionType}</div>
            </div> */}

            <div className="question-box-item">
              <div className="item-1">
                <b>Question ID:</b>
              </div>
              <div className="item-2">{question?.questionId}</div>
            </div>
            <div className="question-box-item">
              <div className="item-1">
                <b>Question:</b>
              </div>
              <div className="item-2" dangerouslySetInnerHTML={{ __html: question.question }}></div>
            </div>
            <div className="question-box-item">
              <div className="item-1">
                <b>Answers:</b>
              </div>
              <div className="item-2">
                {JSON.parse(question.answer).map((item,index)=>{
                  return (
                    <div className="d-flex">
                       {index == 0 ? "A:":index == 1 ? "B:":index == 2? "C:": index == 3? "D:":index == 4? "E:": null}&nbsp;&nbsp;<p dangerouslySetInnerHTML={{ __html: item }}></p>
                       
                    </div>
                    
                  )
                })}
              </div>
            </div>
            <div className="question-box-item">
              <div className="item-1">
                <b>Correct answer:</b>
              </div>
              <div className="item-2" dangerouslySetInnerHTML={{ __html: question.correctAnswerIndex }}></div>
            </div>
            {/* <div className="question-box-item">
              <b>Correct answer:</b> {question?.correctAnswerIndex}
            </div> */}
            <div className="d-flex justify-content-end"></div>

            <div className="d-flex justify-content-between">
              <Button onClick={() => toggleExpansion(index)}>
                Explanation
              </Button>
              <Button onClick={() => handleReplace(question)}>Replace</Button>
            </div>
            {question?.isExpanded && (
              <div>
                <div
                  dangerouslySetInnerHTML={{ __html: question.explanation }}
                />
              </div>
            )}
          </div>
        );
      })}

      {/* </div> */}

      { questions && 

      <div
        className="d-flex justify-content-end mt-3"
        style={{ width: "700px" }}
      >
        <Button
          style={{ background: "#f38406", color: "white" }}
          onClick={() => handleredirect()}
        >
          Save & Proceed
        </Button>
      </div>}
      {/* <div className="shadow" style={{position: 'absolute',right: '20px',top: '20px',borderRadius :'50%' ,background: 'white',padding:'10px 12px',cursor: 'pointer'}} onClick={()=>goBottom()}>
        <FontAwesomeIcon icon={faDownLong}/>
      </div> */}
    </div>
  );
};

export default QuizQuestions;
