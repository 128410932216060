// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { completed, ongoing, upcoming, cancelUpcomingQuiz } from "../../store/services/quizApi";
// import { Tabs, Button } from "antd";
// import { Link, useNavigate } from "react-router-dom";

// // import Countdown from "react-countdown";

// const Leaderboard = () => {
//   const authenticated = useSelector((store) => store.authencation);
//   const [selectedTab, setSelectedTab] = useState(1);
//   const [tabData, setTabData] = useState();

//   const { TabPane } = Tabs;

//   const navigate = useNavigate()

//   const handleCanelQuiz = (quiz) => {
//     cancelUpcomingQuiz({quizId: quiz?.quizId,collegeId: authenticated?.profileInfo?.collegeId }).then((res)=>{console.log(res)})
//   }

//   useEffect(() => {
//     const fetchTabData = async (fetchFunction) => {
//       if (authenticated.isAuthenticated) {
//         const collegeId = authenticated?.profileInfo?.collegeId;
//         const res = await fetchFunction({ collegeId: collegeId });
//         setTabData(res);
//       }
//     };

//     if (selectedTab == 1) {
//       fetchTabData(ongoing);
//     } else if (selectedTab == 2) {
//       fetchTabData(upcoming);
//     } else if (selectedTab == 3) {
//       fetchTabData(completed);
//     }
//   }, [selectedTab, authenticated.isAuthenticated]);

//   const handleUpdateQuiz = (quiz) => {
//     navigate(`/quiz/post`,{state:{quiz: quiz, updatePost: true}})
//   }

//   return (
//     <div style={{ padding: "40px 40px" }}>
//       <Tabs defaultActiveKey="1" onChange={(value) => {setSelectedTab(value);setTabData([])}}>
//         <TabPane tab="Ongoing" key="1">
//           <div className="d-flex flex-wrap">
//             {tabData?.lbCategory?.map((item) => {
//               return item?.quiz.map((quiz, i) => {
//                 return (
//                   <div className="col-lg-3 anatomy-wrapper" key={i}>
//                     <div className="row">
//                       <div className="col-lg-12 align-self-center">
//                         <div className="no-of-questions text-center">
//                           <h5>{quiz?.name}</h5>
//                         </div>
//                       </div>
//                       <div className="col-lg-12 details align-self-center">
//                         <div className="no">
//                           <span>{quiz.totalNoOfQuestions}</span>
//                           <p>Questions</p>
//                         </div>
//                       </div>
//                       <div className="col-lg-12 details align-self-center">
//                         <div className="no">
//                           Closes in {quiz?.closesIn}
//                           {/* <Countdown
//                             date={Date.now() + quiz?.closesIn * 1000}
//                           /> */}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 );
//               });
//             })}
//           </div>
//         </TabPane>
//         <TabPane tab="UpComing" key="2">
//         <div className="d-flex flex-wrap">
//             {tabData?.lbCategory?.map((item) => {
//               return item?.quiz.map((quiz, i) => {
//                 return (
//                   <div className="col-lg-3 anatomy-wrapper" key={i}>
//                     <div className="row">
//                       <div className="col-lg-12 align-self-center">
//                         <div className="no-of-questions text-center">
//                           <h5>{quiz?.name}</h5>
//                         </div>
//                       </div>
//                       <div className="col-lg-12 details align-self-center">
//                         <div className="no">
//                           <span>{quiz.totalNoOfQuestions}</span>
//                           <p>Questions</p>
//                         </div>
//                       </div>
//                       <div className="col-lg-12 details align-self-center">
//                         <div className="no">
//                           Starts in {quiz?.startsIn}
//                           {/* <Countdown
//                             date={Date.now() + quiz?.closesIn * 1000}
//                           /> */}
//                         </div>

//                       </div>
//                       <div className="col-lg-12 d-flex justify-content-around btn-wrapper mt-2">
//                       <Button onClick={()=> handleUpdateQuiz(quiz)}>
//                         Update
//                       </Button>
//                       <Button onClick={()=> handleCanelQuiz(quiz)}>
//                         Cancel
//                       </Button>
//                     </div>
//                     </div>
//                   </div>
//                 );
//               });
//             })}
//           </div>
//         </TabPane>
//         <TabPane tab="Completed" key="3">
//           <div className="d-flex flex-wrap">
//             {tabData?.lbCategory?.map((item) => {
//               return item?.quiz.map((quiz, i) => {
//                 return (
//                   <div className="col-lg-3 anatomy-wrapper" key={i}>
//                     <div className="row">
//                       <div className="col-lg-12 align-self-center">
//                         <div className="no-of-questions text-center">
//                           <h5>{quiz?.name}</h5>
//                         </div>
//                       </div>
//                       <div className="col-lg-12 details align-self-center">
//                         <div className="no">
//                           <span>{quiz.totalNoOfQuestions}</span>
//                           <p>Questions</p>
//                         </div>
//                       </div>
//                       <div>
//                       <div className="col-lg-12 align-self-center text-center btn-wrapper">
//                       <Button>
//                         <Link to={`/leaderboard/${quiz?.quizId}`}>Review</Link>
//                       </Button>
//                     </div>
//                         </div>
//                     </div>
//                   </div>
//                 );
//               });
//             })}
//           </div>
//         </TabPane>
//       </Tabs>
//     </div>
//   );
// };

// export default Leaderboard;

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { Loader } from "components/loader";

import cookies from "react-cookies";
import axios from "axios";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  completed,
  ongoing,
  upcoming,
  cancelUpcomingQuiz,
} from "../../store/services/quizApi";
import { Button } from "antd";
import "../../styles/leaderboard.css";
import { useNavigate, Link } from "react-router-dom";

const tabs = ["Live", "Upcoming", "Completed"];

export const LeaderBoard = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const authenticated = useSelector((store) => store.authencation);

  const dispatch = useDispatch();

  const navigate = useNavigate()

  const [tabData, setTabData] = useState();

  

  const handleCanelQuiz = (quiz) => {
    cancelUpcomingQuiz({quizId: quiz?.quizId,collegeId: authenticated?.profileInfo?.collegeId }).then((res)=>{console.log(res)})
  }

   const handleUpdateQuiz = (quiz) => {
    navigate(`/quiz/post`,{state:{quiz: quiz, updatePost: true}})
  }

  useEffect(() => {
    const fetchTabData = async (fetchFunction) => {
      if (authenticated.isAuthenticated) {
        const collegeId = authenticated?.profileInfo?.collegeId;
        const res = await fetchFunction({ collegeId: collegeId });
        setTabData(res);
        setLoading(false);
      }
    };
    setLoading(true);
    if (selectedTab == 0) {
      fetchTabData(ongoing);
    } else if (selectedTab == 1) {
      fetchTabData(upcoming);
    } else if (selectedTab == 2) {
      fetchTabData(completed);
    }
  }, [selectedTab, authenticated.isAuthenticated]);

  

  // useEffect(() => {
  //   window.scroll({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  return (
    <>
      <div className="leader-board-wrapper pt-5 pl-5">
        <div className="row">
          <div className="col-lg-12">
            <h3>
              <b>Leaderboard</b>
            </h3>
          </div>
          <div className="col-lg-12 tab-wrapper">
            <div className="row">
              <div className="col-lg-10">
                <ul className="profile-nav">
                  {tabs.map((item, i) => (
                    <li key={i}>
                      <a
                        role="button"
                        className={selectedTab === i ? "active" : ""}
                        onClick={() => setSelectedTab(i)}
                      >
                        {item}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {isLoading ? (
            <div className={`loading-screen ${isLoading ? "active" : ""}`}>
              {/* <Loader loadingScreen={isLoading} /> */} loading...
            </div>
          ) : (
            <>
              {tabData?.lbCategory?.map((data, i) => {
                return data?.quiz?.map((item, i) => {
                  return (
                    <div className="col-lg-3 anatomy-wrapper" key={i}>
                      <div className="row">
                        <div className="col-lg-12 align-self-center">
                          <div className="no-of-questions text-center">
                            <h5>{item?.name}</h5>
                          </div>
                        </div>
                        <div className="col-lg-12 details align-self-center">
                          <div className="no">
                            <span>{item.totalNoOfQuestions}</span>
                            <p>Questions</p>
                          </div>
                        </div>
                        <div className="col-lg-12 details align-self-center">
                          <div className="no">
                            {selectedTab === 0 ? <p>Closes at <span>{item?.closesIn}</span></p> : ""}
                            {selectedTab === 1 ? <p>Starts in <span>{item?.startsIn}</span></p> : ""}
                            {selectedTab === 2 ? (
                              <p>
                                Conducted on<span>{item?.conductedOn}</span>
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        
                          {selectedTab === 1 ? (
                            <div className="col-lg-12 d-flex justify-content-around btn-wrapper">
                              <Button onClick={()=> handleUpdateQuiz(item)}>
                                  Update
                                </Button>
                                <Button onClick={()=> handleCanelQuiz(item)}>
                                   Cancel
                                </Button>
                            </div>
                          ) : selectedTab == 2 ? (
                            <div className="col-lg-12 align-self-center text-center btn-wrapper">
                            <Button>
                         <Link to={`/leaderboard/${item?.quizId}`}>Review</Link>
                       </Button>
                            </div>
                          ) : null}
                        {/* </div> */}
                      </div>
                    </div>
                  );
                });
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LeaderBoard;
