import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authenticationInProgress: false,
//   profileInfo: {
//     "facultyId": 100001,
//     "countryCode": 91,
//     "mobileNo": "6300000000",
//     "email": "srikanth@yopmail.com",
//     "name": "Srikanth K",
//     "education": "MBBS",
//     "collegeAdmin": true,
//     "logo": "https://d27vkvqvvwyzde.cloudfront.net/VideoImageURL/logoNew.jpg",
//     "homePageCollegelogo": "https://d2p0vg0bzbv2mt.cloudfront.net/LMSApp/Maheswara/CollegeLogo.png",
//     "homePageDTlogo": "https://d2p0vg0bzbv2mt.cloudfront.net/LMSApp/Maheswara/DTLogo.png",
//     "lastLoginDateTime": "2023-10-12 07:30:26",
//     "createdAt": "2023-10-09 12:17:52",
//     "updatedAt": "2023-10-09 12:24:47",
//     "collegeId": 100001,
// },
//   isAuthenticated: true,
profileInfo: null,
isAuthenticated: false,

};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    AUTHENTICATING_USER: (state) => {
        state.authenticationInProgress = true
        state.isAuthenticated = false
        state.profileInfo = null
    },
    AUTHENTICATING_USER_SUCCESS: (state,action) => {
        state.authenticationInProgress = false
        state.isAuthenticated = true
        state.profileInfo = action.payload

    },
    UPDATE_USER: (state,action) => {
        state.authenticationInProgress = false
        state.isAuthenticated = false
        state.profileInfo = action.payload

    },
    AUTHENTICATING_USER_ERROR: (state) => {
        state.authenticationInProgress = false
        state.isAuthenticated = false
        state.profileInfo = null

    },
    logout: (state) => {
        state.authenticationInProgress = false
        state.isAuthenticated = false
        state.profileInfo = null
    },
  },
});

export const { AUTHENTICATING_USER,AUTHENTICATING_USER_SUCCESS,UPDATE_USER,AUTHENTICATING_USER_ERROR,logout } = loginSlice.actions;

export default loginSlice.reducer;
