import React, { useState, useEffect } from "react";
import { Form, Input, Select, DatePicker, Row, Col, Button, Radio } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllCourse,
  getAllCourseSpecialization,
  getAllCourseSpecializationBatch,
  getAllCourseSpecializationBatchAcadamicYear,
} from "../../store/services/studentApi";
import { useSelector } from "react-redux";
import moment from "moment";
import { postQuiz, updateQuiz } from "../../store/services/quizApi";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QuizPost = () => {
  const location = useLocation();

  const [form] = Form.useForm();

  const [courOpt, setCourOpt] = useState();

  const [specializationOpt, setSpecializationOpt] = useState();

  const [specializBatchOpt, setSpecializBatchOpt] = useState();

  const [batchYearOpt, setBatchYearOpt] = useState();

  const [academicYearId, setAcademicYearId] = useState();

  const navigate = useNavigate();

  const authenticate = useSelector((state) => state.authencation);

  const resetValues = (value) => {
    if (value == "course") {
      setSpecializBatchOpt([]);
      setBatchYearOpt([]);
      form.resetFields(["specialization", "specializationBatch", "batchYear"]);
    } else if (value == "specialization") {
      setBatchYearOpt([]);
      form.resetFields(["specializationBatch", "batchYear"]);
    } else if (value == "specializationBatch") {
      form.resetFields(["batchYear"]);
    }
    setAcademicYearId(null);
  };

  const handleChange = (field, value) => {
    if (field === "course" && value !== null) {
      getAllCourseSpecialization({ collegeCourseId: value }).then((res) => {
        setSpecializationOpt(res?.collegeCourseSpecialization);
        resetValues(field);
      });
    } else if (field === "specialization" && value !== null) {
      getAllCourseSpecializationBatch({ courseSpecializationId: value }).then(
        (res) => {
          setSpecializBatchOpt(res?.collegeCourseSpecializationBatch);
          resetValues(field);
        }
      );
    } else if (field === "specializationBatch" && value !== null) {
      if (value == "All") {
        getAllCourseSpecializationBatchAcadamicYear({ allBatch: true }).then(
          (res) => {
            setBatchYearOpt(res?.collegeCourseSpecializationBatchAcademicYear);
            resetValues(field);
          }
        );
      } else {
        getAllCourseSpecializationBatchAcadamicYear({ batchId: value }).then(
          (res) => {
            setBatchYearOpt(res?.collegeCourseSpecializationBatchAcademicYear);
            resetValues(field);
          }
        );
      }
    } else if (field === "batchYear" && value !== null) {
      setAcademicYearId(value);
      // getStudents({ academicYearId: value }).then((res) => {
      //   setData(res?.collegeStudent);
      // });
      // fetchRecords();
    }
  };

  useEffect(() => {
    if (authenticate?.isAuthenticated) {
      // getStudents({
      //   academicYearId: authenticate?.profileInfo?.facultyId,
      // }).then((res) => setData(res?.collegeStudent));

      getAllCourse({
        collegeId: authenticate?.profileInfo?.collegeId,
      }).then((res) => setCourOpt(res?.collegeCourse));
    }
  }, []);

  const formatDate = (date) => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    return null;
  };

  const handleQuizPost = (values) => {
    let courseId = courOpt.filter(
      (opt) => opt?.collegeCourseId == values?.course
    );

    // let category = specializationOpt.filter((opt)=> opt?.courseSpecializationId == values?.specialization)

    const dateFormat = "YYYY-MM-DD HH:mm:ss";

    if (location?.state?.updatePost) {
      const updateBody = {
        quizId: location?.state?.quiz?.quizId,
        collegeId: authenticate?.profileInfo?.collegeId,
        name: location?.state?.quiz?.name,
        testDuration: parseInt(values.testDuration),
        perQuestionMarks: parseFloat(values.perQuestionMarks),
        negativeMarksPerQuestion: parseFloat(values?.negativeMarksPerQuestion),
        startDate: formatDate(new Date(values.startDate)),
        endDate: formatDate(new Date(values.endDate)),
        category: values?.category,
        courseId: parseInt(courseId[0]?.courseId),
        quizAcademicYears: values?.batchYear.map((batch) => {
          return {
            academicYearId: batch,
          };
        }),
        generateRandomTest: values?.random == 'True' ? true : false,
        notifyNow: values?.notify == 'True'? true : false ,
        notifyBeforeTest: values?.notify == 'True'? true : values?.notify == 'False' ? true : false
      };

      updateQuiz(updateBody).then((res) => {
        navigate("/leaderboard");
      });

    } else {
      const body = {
        quizId: location?.state?.quiz?.quizId,
        collegeId: authenticate?.profileInfo?.collegeId,
        name: location?.state?.quiz?.quizName,
        testDuration: parseInt(values.testDuration),
        perQuestionMarks: parseFloat(values.perQuestionMarks),
        negativeMarksPerQuestion: parseFloat(values?.negativeMarksPerQuestion),
        startDate: formatDate(new Date(values.startDate)),
        endDate: formatDate(new Date(values.endDate)),
        category: values?.category,
        courseId: parseInt(courseId[0]?.courseId),
        quizAcademicYears: values?.batchYear.map((batch) => {
          return {
            academicYearId: batch,
          };
        }),
        generateRandomTest: values?.random == 'True' ? true : false,
        notifyNow: values?.notify == 'True'? true : false ,
        notifyBeforeTest: values?.notify == 'True'? true : values?.notify == 'False' ? true : false
      };



      postQuiz(body).then((res) => {
        if (res?.status?.isSuccess == false){

        }else{
          navigate("/leaderboard");
        }
       
      });
    }
  };

  //   function onChange(date, dateString) {
  //     const dateFormat = "YYYY-MM-DD HH:mm:ss";
  //     console.log(dateString); // This will log the selected date in the desired format
  //     moment(dateString).format(dateFormat)
  //   }

  useEffect(() => {
    if (location?.state?.post) {
      form.setFieldsValue({
        // id: value.id,
        quizName: location?.state?.quiz?.quizName,
      });
    } else if (location?.state?.updatePost) {
      const dateFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
      // 2023-10-31T09:09:25.027Z
      const startDate = moment(location?.state?.quiz?.startDate);
      const endDate = moment(location?.state?.quiz?.endDate);
      // console.log(startDate)
      form.setFieldsValue({
        quizName: location?.state?.quiz?.name,
        perQuestionMarks: location?.state?.quiz?.perQuestionMarks,
        negativeMarksPerQuestion:
          location?.state?.quiz?.negativeMarksPerQuestion,
        testDuration: location?.state?.quiz?.testDuration,
        category: location?.state?.quiz?.category,
        // startDate:  startDate,
        // endDate: endDate,
        // perQuestionMarks: location?.state?.quiz?.quizName,
        // perQuestionMarks: location?.state?.quiz?.quizName,
      });
    }
  }, [location?.state?.quiz]);

  return (
    <div className="p-4">
      <div>
        <h3>{location?.state?.updatePost ? "Update Quiz" : "Post Quiz"}</h3>
      </div>
      <div
        className="shadow p-3"
        style={{ maxWidth: "900px", background: "white", borderRadius: "5px" }}
      >
        <Form
          form={form}
          layout="vertical"
          colon={false}
          onFinish={handleQuizPost}
        >
          
          <Row gutter={16}>
            <Col  xs={24} sm={12} md={6}>
              <Form.Item
                name="quizName"
                label="Quiz Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled value={location?.state?.quiz?.name} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label="Course"
                name="course"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(value) => handleChange("course", value)}
                  placeholder="Select Course"
                >
                  {courOpt?.map((item) => {
                    return (
                      <Select.Option value={item?.collegeCourseId}>
                        {item?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label="Specialization"
                name="specialization"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(value) => handleChange("specialization", value)}
                  placeholder="Select Specialization"
                >
                  {specializationOpt?.map((item) => {
                    return (
                      <Select.Option value={item?.courseSpecializationId}>
                        {item?.specialzationName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label="Specialization Batch"
                name="specializationBatch"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(value) =>
                    handleChange("specializationBatch", value)
                  }
                  placeholder="Select Specialization Batch"
                >
                  {specializBatchOpt && specializBatchOpt.length > 0 && (
                    <Select.Option value="All">All</Select.Option>
                  )}
                  {specializBatchOpt?.map((item) => {
                    return (
                      <Select.Option value={item?.batchId}>
                        {item?.yearOfAdmission}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label="Academic Year"
                name="batchYear"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(value) => handleChange("batchYear", value)}
                  placeholder="Select Academic Year"
                  mode="multiple"
                >
                  {batchYearOpt?.map((item) => {
                    return (
                      <Select.Option value={item?.acedmicYearId}>
                        {item?.academicYearName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label="Category"
                name="category"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select placeholder="Select Category">
                  <Select.Option value="Subject Test" >
                    Subject Test
                  </Select.Option>
                  <Select.Option value="Grand Test">Grand Test</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label="Marks Per Question"
                name="perQuestionMarks"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label="Negative Marks / Question"
                name="negativeMarksPerQuestion"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label={
                  <span>
                    Test Duration (
                    <span style={{ color: "#a59f9f" }}>in Minutes</span>)
                  </span>
                }
                name="testDuration"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label="Start Date"
                name="startDate"
                htmlType="date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  format={"YYYY-MM-DD HH:mm"}
                  showTime={{ format: "HH:mm" }}
                  //   onChange={onChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  format={"YYYY-MM-DD HH:mm"}
                  showTime={{ format: "HH:mm" }}
                />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label={<div>Question Order <FontAwesomeIcon icon = {faCircleInfo} title="Random (Different Order for Each User) / Same (Uniform Order for All Users)" style={{background : 'transparent'}}/></div>}
                name="random"
                initialValue={"False"}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  defaultValue="False"
                  placeholder="Select Order Type"
                >
                  <Select.Option value="True">
                        Random Order
                  </Select.Option>
                  <Select.Option value="False">
                        Same Order
                  </Select.Option>
                </Select>
               
                
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label={<div>Notify Student <FontAwesomeIcon icon = {faCircleInfo} title="" style={{background : 'transparent'}}/></div>}
                name="notify"
                initialValue={"False"}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                 defaultValue="False"
                  // placeholder="Select Order Type"
                >
                  <Select.Option value="True">
                        Notify now and before test
                  </Select.Option>
                  <Select.Option value="False">
                        Notify before test only
                  </Select.Option>
                </Select>
               
                
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item label={true}>
              <Button htmlType="submit" style={{width: '100%'}}>Submit</Button>
              </Form.Item>
            
            </Col>
          </Row>
          
        </Form>
      </div>
    </div>
  );
};

export default QuizPost;
