import React, { useState, useRef, useEffect } from "react";
import Background from "../assests/background-logo.jpg";
import Logo from "../assests/pngtree-scool-college-logo-victor-png-image_9026968.png";
import Logo2 from "../assests/logo.png";
import fb from "../assests/FB.png";
import insta from "../assests/insta.png";
import yt from "../assests/YT.png";
import telegram from "../assests/Telegram.png";
import { useNavigate, Navigate } from "react-router-dom";
import { Input, Button, Form, InputNumber, Row, Col } from "antd";
import { sendOTP, VerifyOTPLogin } from "../store/authSlice/api";
import { Select } from "antd";
import country from "../assests/json/country.json";
import { InputOTP } from "antd-input-otp";
import { useDispatch } from "react-redux";
import { AUTHENTICATING_USER_SUCCESS } from "../store/authSlice/loginSlice";
import { useSelector } from "react-redux";
import { axiosRequestSilentPublic } from "../store/api-v1";
import OtpInput from "../components/OtpInput";
import { toast } from "react-toastify";

const Login = () => {
  const [form] = Form.useForm();
  const [otpform] = Form.useForm();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [state, setState] = useState({
    countryCode: "",
    phoneNumber: "",
    otpToken: "",
    facultyId: "",
  });
  const authenticated = useSelector(
    (state) => state.authencation.isAuthenticated
  );
  const initialValues = {
    countryCode: "+91",
    phoneNumber: "",
  };

  const handlesubmit = async (values) => {

    if (step == 1) {
      const gatewayResponse = await sendOTP({
        countryCode: values.countryCode,
        mobileNo: values.phoneNumber,
      });
      if (gatewayResponse.status.isSuccess) {
        setStep(2);
        setState({
          ...state,
          countryCode: values.countryCode,
          phoneNumber: values.phoneNumber,
          facultyId: gatewayResponse.response.facultyId,
          otpToken: gatewayResponse.response.otpToken,
        });
      }
    } else if (step == 2) {
      // dispatch(AUTHENTICATING_USER_SUCCESS({ facultyId: 10001 }));
      // navigate("/");
      const gatewayResponse2 = await VerifyOTPLogin({
        otpToken: state.otpToken,
        facultyId: state.facultyId,
        otp: values?.otp.join(""),
      });
      if (gatewayResponse2) {
        dispatch(AUTHENTICATING_USER_SUCCESS(gatewayResponse2));
        navigate("/");
      }
      else{
        toast.error("Invalid OTP")
      }
    }
  };

  useEffect(() => {

    if (authenticated) {
      navigate("/");
    }
  }, [authenticated]);

  const fetchimages = async () => {
    const results = await axiosRequestSilentPublic(
      "POST",
      "/lmsv2",
      "/getCollegeHomePageApiInfo",
      {
        collegeId: 100001,
      },
      {}
    );
    if (results.data.gatewayResponse.status.isSuccess) {
      return results.data.gatewayResponse.response;
    }
    return results.data;
  };

  useEffect(() => {
    fetchimages().then((res) => {
      setData(res.CollegeHomePageApiInfo);
    });
  }, []);

  // if (authenticated) {
  //   console.log("yes coming hereeeeeeeeee")
  //   // navigate('back');
  //   return <Navigate to="/" replace />;
  // }

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="d-flex login-component">
      <div
        style={{ width: "40%", height: "100vh" }}
        className="login-side-logo"
      >
        <img
          src={Background}
          alt="background logo"
          className="background-logo"
        />
        <img src={Logo} alt="logo" className="logo" />
        <div className="logo-text">
          <h1>Welcome</h1>
          <p>{data && data[0]?.homePageCollegelogoText} </p>
        </div>
      </div>
      <div className="login-box">
        <div>
          <img src={Logo2} alt="small-logo" width="100" />
        </div>
        <div>
          <h6>
            <b>Login with your doctutorials account</b>
          </h6>
        </div>
        <Form
          onFinish={handlesubmit}
          name="loginForm"
          form={form}
          initialValues={initialValues}
        >
          <div className="d-flex gap-3" style={{ gap: "10px" }}>
            <div
              style={{
                padding: "10px",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                borderRadius: "10px",
              }}
            >
              <Form.Item name="countryCode">
                <Select
                  style={{ width: "80px" }}
                  showSearch
                  optionFilterProp="children"
                  // onChange={onChange}
                  // onSearch={onSearch}
                  filterOption={filterOption}
                  optionLabelProp="value"
                  options={Object.entries(country).map((item) => ({
                    value: "+" + item[1][1],
                    label: item[1][0] + " " + item[1][1],
                  }))}
                  // dropdownRender={(menu) => (
                  //   <div>
                  //     {menu}
                  //     <div style={{ display: "none", width: "100px" }}>
                  //       {Object.entries(country).map((item) => (
                  //         <div key={item[1][1]}>
                  //           {item[1][0] + " " + item[1][1]}
                  //         </div>
                  //       ))}
                  //     </div>
                  //   </div>
                  // )}
                  dropdownStyle={{ width: "200px" }}
                />
              </Form.Item>
            </div>
            <div
              style={{
                background: "white",
                width: "250px",
                padding: "10px",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                borderRadius: "10px",
              }}
            >
              <Form.Item
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Phone number is required.",
                  },
                ]}
                initialValue="Default Value"
              >
                <Input
                  maxLength={10}
                  placeholder="Enter Phone Number"
                  style={{ outline: "none", border: "none" }}
                  // defaultValue={initialValues.phoneNumber}
                  // value={form.phoneNumber}
                />
              </Form.Item>
            </div>
          </div>
          <div  className="d-flex justify-content-center p-2 pt-4">
          <Form.Item>
            <Button
              htmlType="submit"
              style={{ marginLeft: "auto", marginRight: "auto" }}
              disabled = { step == 2? true:false}
            >
              Get Otp
            </Button>
          </Form.Item>

          </div>
          
        </Form>

        {step == 2 ? (
          <Form form={otpform} onFinish={handlesubmit}>
            <Form.Item name="otp">
              <InputOTP autoFocus inputType="numeric" autoSubmit={otpform} length={4} />
            </Form.Item>
            <Form.Item noStyle>
              <Button
                block
                htmlType="submit"
                type="primary"
                style={{
                  background: "white",
                  color: "#4096ff",
                  borderColor: "#4096ff",
                  marginTop: "10px"
                }}
              >
                Verify
              </Button>
            </Form.Item>
          </Form>
        ) : null}

        {/* {step == 2 ? <OtpInput onOtpSubmit={handlesubmit}/>:null} */}
         
        <div className="footer-box">
          <div>Follow us</div>
          <div className="d-flex social-icons">
            <img src={insta} onClick={()=>window.open("https://www.instagram.com/doctutorials_","_blank")}/>
            <img src={fb} onClick={()=>window.open("https://www.facebook.com/doctutorials","_blank")}/>
            <img src={yt} onClick={()=>window.open("https://www.youtube.com/doctutorials","_blank")}/>
            <img src={telegram} onClick={()=>window.open("https://t.me/DocTutorials","_blank")}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
