import "./App.css";
import { ToastContainer } from "react-toastify";
// import Header from './components/Header';
// import Sidebar from './components/Sidebar';
import Routes from "./Routes/routes";
import 'react-toastify/dist/ReactToastify.css';
// import 'font-awesome/css/font-awesome.min.css';
// import Login from './pages/login'
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tryToAuthenticate } from "./store/authSlice/api";
import { AUTHENTICATING_USER, AUTHENTICATING_USER_SUCCESS } from "./store/authSlice/loginSlice";

function App() {
    
  const authenticated = useSelector(
    (state) => state.authencation.isAuthenticated
  );
  const loginDetails = useSelector(
    (state) => state.authencation
  );
  const dispatch = useDispatch()
  // const navigate = useNavigate();
  useEffect(() => {
    if (!authenticated){
    dispatch(AUTHENTICATING_USER())
    const tryToAuthenticate1 = JSON.parse(localStorage.getItem('localStoragedata'))
    if (tryToAuthenticate1 !== null) {
      dispatch(AUTHENTICATING_USER_SUCCESS(tryToAuthenticate1))
    }
  }
    // tryToAuthenticate(dispatch)
  }, [authenticated]);
  
  return (
    <div className="App">
      {/* {JSON.stringify(loginDetails)} */}
      {/* <Login/>
      <Header/>
      <Sidebar /> */}
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Routes />
    </div>
  );
}

export default App;
