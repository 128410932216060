import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { studentReview } from "../../store/services/quizApi";
import { toast } from "react-toastify";
import axios from "axios";
import {
  Label,
  Input,
  Form,
  FormGroup,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import "../../styles/leaderboard-review.css";
import ReactPaginate from "react-paginate";

const LeaderboardReview = (props) => {
  const location = useLocation();

  const [studentQuizData, setStudentQuizData] = useState([]);

  const [questions, setQuestions] = useState([]);

  const [isPaused, setPaused] = useState(false);

  const [filterValue, setFilterValue] = useState("ALL");

  const windowWidth = window.innerWidth;

  const [activePage, setActivePage] = useState(
    localStorage.getItem("activePage")
      ? parseInt(localStorage.getItem("activePage"))
      : 0
  );

  const handleQuestionAnswers = () => {};

  const setfilteredQuestions = (active) => {
    let question = [];
    studentQuizData.length > 0 &&
      studentQuizData.map((item) => {
        question.push(item);
      });

    if (filterValue == "RIGHT") {
      question = question.filter(
        (item) => item?.responseIndex === item?.correctAnswerIndex
      );

      setActivePage(active);
    } else if (filterValue == "WRONG") {
      question = question.filter(
        (item) =>
          !item.skipped && item?.responseIndex !== item?.correctAnswerIndex
      );
      setActivePage(active);
    } else if (filterValue == "SKIPPED") {
      question = question.filter((item) => item.skipped == true);
      setActivePage(active);
    } else if (filterValue == "BOOKMARKS") {
      question = question.filter((item) => item.bookmarked == true);

      setActivePage(active);
    }
    setQuestions(question);
  };

  useEffect(() => {
    studentReview({
      studentId: location.state?.studentId,
      quizId: location.state?.quizId,
      assessmentId: location.state?.assessmentId,
    }).then((response) => {
      if (response?.reviewJson) {
        let studentData = atob(response?.reviewJson);

        let parsedData = JSON.parse(studentData);

        setStudentQuizData(parsedData);
        setQuestions(parsedData);
      }
    });
  }, [location.state]);

  useEffect(() => {
    setfilteredQuestions(0);
  }, [filterValue]);

  const gettimetaken = (seconds) => {
    if (!seconds) {
      return 0;
    }
    const minutes = Math.floor(seconds / 60);
    const secondsString = String(seconds % 60).padStart(2, "0");
    const durationString = `${minutes}:${secondsString}`;
    return durationString;
  };

  const handlePageClick = (event) => setActivePage(event.selected);

  return (
    <div>
      <div className="col-lg-5 align-self-center mb-4 mt-4">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "12px",
            width: "415px",
          }}
        >
          <div>
            <span>
              Time Taken:{" "}
              <span className="time" style={{ fontSize: "11px" }}>
                {gettimetaken(questions[activePage]?.timeSpent)}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="mb-4 pl-4">
        <ul className="profile-nav p-2 p-md-0">
          <li>
            <a
              role="button"
              className={`d-block ${filterValue === "ALL" ? "active" : ""}`}
              onClick={() => setFilterValue("ALL")}
            >
              All
            </a>
          </li>
          <li>
            <a
              role="button"
              className={`d-block ${filterValue === "RIGHT" ? "active" : ""}`}
              onClick={() => setFilterValue("RIGHT")}
            >
              Right
            </a>
          </li>
          <li>
            <a
              role="button"
              className={`d-block ${filterValue === "WRONG" ? "active" : ""}`}
              onClick={() => setFilterValue("WRONG")}
            >
              Wrong
            </a>
          </li>
          <li>
            <a
              role="button"
              className={`d-block ${filterValue === "SKIPPED" ? "active" : ""}`}
              onClick={() => setFilterValue("SKIPPED")}
            >
              skipped
            </a>
          </li>
          {/* <li className="w-100 w-md-auto d-block d-md-inline-block">
                    <a
                      role="button"
                      className={`d-block ${
                        filterValue === "BOOKMARKS" ? "active" : ""
                      }`}
                      onClick={() => setFilterValue("BOOKMARKS")}
                    >
                      Bookmarks
                    </a>
                  </li> */}
        </ul>
      </div>
      <div className="row leaderboard-review">
      <Question
        total={questions?.length}
        question={questions[activePage]}
        onSubmit={handleQuestionAnswers}
        answeredData={questions[activePage]}
        activePage={activePage}
        moduleid={122}
        bookmarkQuestion={111}
        isPaused={isPaused}
        saveQuestion={handleQuestionAnswers}
        review={true}
        quizId={location?.state?.quizId}
        setRefreshQuestions={handleQuestionAnswers}
      />
      </div>

      <div className="pagination mt-3 ms-3 col-lg-6 ml-4">
        <ReactPaginate
          breakLabel={windowWidth <= 4 ? "..." : ""}
          className="pagination"
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={
            windowWidth <= 480 ? 1 : windowWidth <= 450 ? 0 : 2
          }
          marginPagesDisplayed={
            windowWidth <= 480 ? 1 : windowWidth <= 450 ? 0 : 2
          }
          pageCount={questions.length}
          previousLabel="<<"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

export default LeaderboardReview;

let questionTimer;
const Question = ({
  total,
  question,
  answeredData,
  activePage,
  setActivePage,
  onSubmit,
  bookmarkQuestion,
  isPaused,
  saveQuestion,
  review,
  quizId,
  setRefreshQuestions,
}) => {
  const location = useLocation();
  const [questionTime, setQuestionTime] = useState(0);
  const [opneExplanationImage, setopneExplanationImage] = useState(false);
  const [explanation, setexplanation] = useState(false);
  const [imageTag, setimageTag] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setQuestionTime(0);
  }, [question?.questionId]);

  useEffect(() => {
    if (isPaused) clearInterval(questionTimer);
    else
      questionTimer = setInterval(
        () => setQuestionTime((questionTime) => questionTime + 1),
        1000
      );
    return () => {
      clearInterval(questionTimer);
    };
  }, [isPaused]);

  const isAnswered = !!answeredData;

  const answers =
    (() => {
      try {
        return question?.options;
      } catch (err) {
        return [];
      }
    })() || [];

  const handleSubmit = (option) => {
    if (isAnswered) {
      answeredData.responseIndex = option;
      answeredData.timeSpent = questionTime;
      // answeredData.skipped = false;
      if (answeredData.marked) {
        answeredData.markedResponseIndex = true;
        delete answeredData.marked;
      }
      onSubmit(answeredData);
    } else {
      onSubmit({
        timeSpent: questionTime,
        responseIndex: option,
        correctAnswerIndex: question?.correctAnswerIndex,
        visited: true,
        subjectId: question.subjectId,
        questionId: question.questionId,
      });
    }
  };

  function bookmarkquestion() {
    window.scrollTo(0, 0);
    if (answeredData) {
      answeredData.markedResponseIndex = true;
      onSubmit(answeredData);
    } else {
      onSubmit({
        timeSpent: questionTime,
        correctAnswerIndex: question?.correctAnswerIndex,
        visited: true,
        marked: true,
        subjectId: question.subjectId,
        questionId: question.questionId,
      });
    }
    if (total > activePage + 1) {
      setActivePage(activePage + 1);
    }
  }
  const savequestion = () => {
    if (answeredData) {
      if (answeredData.marked) {
        answeredData.markedResponseIndex = true;
        delete answeredData.marked;
      }
      onSubmit(answeredData);
    } else {
      onSubmit({
        timeSpent: questionTime,
        correctAnswerIndex: question?.correctAnswerIndex,
        subjectId: question.subjectId,
        visited: true,
        questionId: question.questionId,
      });
    }
    if (total > activePage + 1) {
      setActivePage(activePage + 1);
    }
    window.scrollTo(0, 0);
  };

  const bookmarkquestionintestseries = async () => {};

  function clearResponce() {
    if (answeredData) {
      onSubmit({
        remove: true,
        timeSpent: questionTime,
        correctAnswerIndex: question?.correctAnswerIndex,
        subjectId: question.subjectId,
        questionId: question.questionId,
      });
    }
  }
  if (!question) return null;

  const htmlString = explanation ? question?.explanation : question?.question;
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlString, "text/html");
  const imgTag = htmlDoc.querySelector("img");

  const handleClickExplanation = (event) => {
    setimageTag(event?.target);
    if (event?.target?.src) {
      setopneExplanationImage(true);
    }
    setexplanation(true);
  };
  const handleClickQuestion = (event) => {
    setimageTag(event?.target);
    if (event?.target?.src) {
      setopneExplanationImage(true);
    }
    setexplanation(false);
  };

  const showExplanation = (question) => {
    return (
      <>
        <div
          className="explanations-wrapper col-lg-4"
          key={question?.questionId}
        >
          <div>
            <b>Explanation</b>
          </div>
          <div
            className="explanation-text"
            onClick={
              handleClickExplanation /* () => { imgTag !== null && setopneExplanationImage(true); setexplanation(true) } */
            }
          >
            <div dangerouslySetInnerHTML={{ __html: question.explanation }} />
          </div>

          {/* <div className="text-center bg-white">
            <button type="button" class="btn btn-outline-custom" data-toggle="modal" data-target="#exampleModal">Feedback/Report Error</button>
          </div> */}
        </div>
      </>
    );
  };

  return (
    <>
      {/* <div className="exam-title col-lg-10">
          <b>{location.state?.moduleName}</b>
        </div> */}
      <div className="question-wrapper col-lg-7">
        <div className="question">
          <div className="row">
            <div className="col-lg-1 col-2">
              <span className="question-no text-center">{activePage + 1}</span>
            </div>
            <div className="col-lg-10">
              <div
                className="question-text"
                onClick={
                  handleClickQuestion /* () => { imgTag !== null && setopneExplanationImage(true); setexplanation(false) } */
                }
              >
                <div dangerouslySetInnerHTML={{ __html: question.question }} />
              </div>

              <div className="options">
                <Form>
                  <FormGroup check inline>
                    {answers.map((option, i) => {
                      return (
                        <>
                          <div
                            key={i}
                            className={
                              isAnswered && review
                                ? answeredData?.responseIndex == i
                                  ? question.correctAnswerIndex == i
                                    ? "alert-success d-flex"
                                    : "alert-danger d-flex"
                                  : question.correctAnswerIndex == i
                                  ? "alert-success d-flex"
                                  : "d-flex"
                                : "d-flex"
                            }
                          >
                            <Input
                              disabled={review}
                              type="radio"
                              checked={answeredData?.responseIndex == i}
                              onClick={() => handleSubmit(i)}
                            />
                            {i === 0
                              ? "(A) "
                              : i === 1
                              ? "(B) "
                              : i === 2
                              ? "(C) "
                              : i === 3
                              ? "(D) "
                              : ""}
                            <Label
                              check
                              onClick={review ? null : () => handleSubmit(i)}
                              dangerouslySetInnerHTML={{ __html: option }}
                            />
                          </div>
                        </>
                      );
                    })}
                  </FormGroup>
                </Form>
              </div>
              {location.state?.state !== "test-series" && (
                <div className="text-muted">
                  © Doctutorials MCQID: {question.questionId}
                </div>
              )}
            </div>
            {review && (
              <div className="notes-wrapper" style={{ marginTop: "20px" }}>
                <div className="row">
                  <div className="col-lg-12 col-12">
                    <span className="percentage text-center">
                      {question?.correctPer}%
                    </span>
                    answered this question correctly
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!review && (
        <div className="row btn-wrapper">
          <div className="col-lg-4 text-left">
            <Button
              className={`${
                answeredData && answeredData.marked
                  ? "active"
                  : answeredData && answeredData.markedResponseIndex
                  ? "active"
                  : ""
              }`}
              onClick={bookmarkquestion}
            >
              Mark for Review & Next
            </Button>
          </div>
          <div className="col-lg-4 text-right">
            <Button onClick={clearResponce}>Clear Response</Button>
          </div>
          <div className="col-lg-4 text-right">
            {total != activePage + 1 ? (
              <Button
                className={`save ${
                  answeredData && answeredData.marked
                    ? ""
                    : answeredData && answeredData.markedResponseIndex
                    ? ""
                    : "active"
                }`}
                onClick={savequestion}
              >
                Save & Next
              </Button>
            ) : null}
          </div>
        </div>
      )}

      {review && question.explanation && showExplanation(question)}
    </>
  );
};
