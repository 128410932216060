import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Table,
  Tag,
  Form,
  Select,
  Row,
  Col,
  Modal,
  Input,
} from "antd";
import {
  axiosRequestSilent,
  axiosRequestSilentPublic,
  axiosRequestNA,
} from "../../store/api-v1";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  addStudent,
  deleteStudent,
  updateStudent,
  getAllCourse,
  getAllCourseSpecialization,
  getAllCourseSpecializationBatch,
  getAllCourseSpecializationBatchAcadamicYear,
} from "../../store/services/studentApi";
import { Link, useNavigate } from "react-router-dom";

const Students = () => {
  const [data, setData] = useState();
  const [form] = Form.useForm();
  const authenticate = useSelector((state) => state.authencation);
  const userType = useSelector((state) => state.authencation?.profileInfo?.userType);
  const [addStudentModalOpen, setAddStudentModal] = useState(false);
  const [academicYearId, setAcademicYearId] = useState(localStorage.getItem("academicYearId"));
  const [studentFilterDet, setStudentFilterDet] = useState({
    course: undefined,
    specialization: undefined,
    specializationBatch: undefined,
    batchYear: undefined,
  });

  const [studentEdit, setStudentEdit] = useState(false)

  const [studentEditdetails, setStudentEditdetails] = useState()

  const [courOpt, setCourOpt] = useState();

  const navigate = useNavigate()

  const [specializationOpt, setSpecializationOpt] = useState();

  const [specializBatchOpt, setSpecializBatchOpt] = useState();

  const [batchYearOpt, setBatchYearOpt] = useState();

  const columns = [
    {
      title: "S/no",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Roll no",
      dataIndex: "rollNo",
      key: "rollNo",
    },
    {
      title: "Mobile",
      dataIndex: "mobileNo",
      key: "mobileNo",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleStudentDelete(record)} disabled={userType=="Admin"?false:true}>
            <FontAwesomeIcon icon={faTrash} color="red" />
          </Button>
          <Button onClick={() => handleStudentEdit(record)} disabled={userType=="Admin"?false:true}>
            <FontAwesomeIcon
              icon={faEdit}
              
            />
          </Button>
        </Space>
      ),
    },
    {
      title: "#App Installed",
      dataIndex: "appInstalled",
      key: "appInstalled",
      render: (text, record) => {
        if (record.appInstalled) {
          
          return <span>Installed</span>;
        } else {
          
          return <span>Not Installed</span>;
        }
      },
    },
    {
      title: "Analysis",
      dataIndex: "analysis",
      key: "analysis",
      render: (_, record) => (
        <Space size="middle">
          <a role="button" onClick={()=>goToStudentAnalysis(record)} className="btn btn-light" >View </a>
        </Space>
      ),
    },
    // {
    //   title: "Links",
    //   dataIndex: "App installed",
    //   key: "App installed",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Button>Send App Link </Button>
    //     </Space>
    //   ),
    // },
  ];

  const goToStudentAnalysis = (record) => {
    localStorage.setItem("academicYearId",academicYearId)
    navigate("/students/analysis",{state:{student: record}})
  }

  const fetchRecords = () => {
    getStudents({ academicYearId: academicYearId }).then((res) => {
      setData(res?.collegeStudent);
    });
  };

  const handleStudentDelete = (student) => {
    deleteStudent({
      studentId: student?.studentId,
    }).then((res) => {
      fetchRecords();
    });
  };

  const handleStudentEdit = (student) => {
    setStudentEdit(true)
    setStudentEditdetails(student)
    setAddStudentModal(true)
  };

  const getStudents = async (body) => {
    try {
      const res = await axiosRequestSilentPublic(
        "POST",
        "/lmsv2",
        "/getCollegeStudent",
        body,
        {}
      );
      if (res.data.gatewayResponse.status.isSuccess) {
        return res.data.gatewayResponse.response;
      } else {
        toast.error(res.data.gatewayResponse.status.message);
        return res.data.gatewayResponse;
      }
    } catch (error) {
      throw new Error("Could not get student details");
    }
  };

  useEffect(() => {
    if (authenticate?.isAuthenticated) {
      // getStudents({
      //   academicYearId: authenticate?.profileInfo?.facultyId,
      // }).then((res) => setData(res?.collegeStudent));

      getAllCourse({
        collegeId: authenticate?.profileInfo?.collegeId,
      }).then((res) => setCourOpt(res?.collegeCourse));
    }
  }, []);

  useEffect(()=>{
    if (academicYearId){
      fetchRecords()
    } 
    
  },[academicYearId])

  // useEffect(()=>{
  //    setStudentEditdetails({})
  //   //  sete
  // },[addStudentModalOpen])

  const resetValues = (value) => {
    if (value == "course") {
      setSpecializBatchOpt([]);
      setBatchYearOpt([]);
      form.resetFields(["specialization", "specializationBatch", "batchYear"]);
    } else if (value == "specialization") {
      setBatchYearOpt([]);
      form.resetFields(["specializationBatch", "batchYear"]);
    } else if (value == "specializationBatch") {
      form.resetFields(["batchYear"]);
    }
    setAcademicYearId(null)
  };

  const handleChange = (field, value) => {
    if (field === "course" && value !== null) {
      getAllCourseSpecialization({ collegeCourseId: value }).then((res) => {
        setSpecializationOpt(res?.collegeCourseSpecialization);
        resetValues(field);
      });
    } else if (field === "specialization" && value !== null) {
      getAllCourseSpecializationBatch({ courseSpecializationId: value }).then(
        (res) => {
          setSpecializBatchOpt(res?.collegeCourseSpecializationBatch);
          resetValues(field);
        }
      );
    } else if (field === "specializationBatch" && value !== null) {
      getAllCourseSpecializationBatchAcadamicYear({ batchId: value }).then(
        (res) => {
          setBatchYearOpt(res?.collegeCourseSpecializationBatchAcademicYear);
          resetValues(field);
        }
      );
    } else if (field === "batchYear" && value !== null) {
      setAcademicYearId(value);
      localStorage.setItem("academicYearId",value)
      // getStudents({ academicYearId: value }).then((res) => {
      //   setData(res?.collegeStudent);
      // });
      // fetchRecords();
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <div>
        <Form form={form} colon={false} layout="vertical">
          <Row gutter={16}>
            <Col  xs={24} sm={12} md={6}>
              <Form.Item label="Course" name="course">
                <Select onChange={(value) => handleChange("course", value)} placeholder="Select Course">
                  {courOpt?.map((item) => {
                    return (
                      <Select.Option value={item?.collegeCourseId}>
                        {item?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item label="Specialization" name="specialization">
                <Select
                  onChange={(value) => handleChange("specialization", value)}
                  placeholder="Select Specialization"
                >
                  {specializationOpt?.map((item) => {
                    return (
                      <Select.Option value={item?.courseSpecializationId}>
                        {item?.specialzationName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label="Year of Joining"
                name="specializationBatch"
              >
                <Select
                  onChange={(value) =>
                    handleChange("specializationBatch", value)
                  }
                  placeholder="Select Specialization Batch"
                >
                  {specializBatchOpt?.map((item) => {
                    return (
                      <Select.Option value={item?.batchId}>
                        {item?.yearOfAdmission}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item label="Education" name="batchYear">
                <Select onChange={(value) => handleChange("batchYear", value)} placeholder="Select Batch Year">
                  {batchYearOpt?.map((item) => {
                    return (
                      <Select.Option value={item?.acedmicYearId}>
                        {item?.academicYearName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <p>
            <b>Student List</b>
          </p>
        </div>
        {
          userType == "Admin" ? 
        
        <div>
          <Button
            style={{ background: "orange", color: "#fff" }}
            onClick={() => setAddStudentModal(true)}
            disabled={academicYearId?false:userType=="Admin"?false:true}
          >
            + Add Student
          </Button>
        </div>:null}
      </div>
      <Table columns={userType=="Admin"? columns : columns.filter((item)=> item.title != "Action")} dataSource={data} pagination={false} />
      <Modal
        title="Add Student"
        open={addStudentModalOpen}
        centered
        onOk={() => {setAddStudentModal(false);setStudentEdit(false);setStudentEditdetails({})}}
        onCancel={() => {setAddStudentModal(false);setStudentEdit(false);setStudentEditdetails({})}}
        width={800}
        footer={null}
      >
        <AddStudents
          academicYearId={academicYearId}
          fetchRecords={fetchRecords}
          studentEdit={studentEdit}
          initialvalues={studentEditdetails}
          setStudentEdit={setStudentEdit}
          setAddStudentModal={setAddStudentModal}
        />
      </Modal>
    </div>
  );
};

export default Students;

const AddStudents = ({
  academicYearId,
  fetchRecords,
  initialvalues,
  studentEdit,
  setStudentEdit,
  setAddStudentModal
}) => {
  const [form] = Form.useForm();

  const handleAddStudent = (values) => {
    if (studentEdit) {
      updateStudent({
        studentId: initialvalues?.studentId,
        academicYearId: academicYearId,
        name: values.name,
        countryCode: 91,
        mobileNo: values.mobileNo,
        email: values.email,
        rollNo: values.rollNo == undefined ? null : values.rollNo
      }).then((res) => {
        fetchRecords();
        setStudentEdit(false)
        setAddStudentModal(false)
        // form.resetFields()
      });
    } else {
      
      addStudent({
        academicYearId: academicYearId,
        name: values.name,
        countryCode: 91,
        mobileNo: values.mobileNo,
        email: values.email,
        rollNo: values.rollNo == undefined ? null : values.rollNo
      }).then((res) => {
        
        fetchRecords();
        form.resetFields()
      });
      
    }
  };

  useEffect(()=>{
    if (studentEdit){
      form.setFieldsValue({
        // id: value.id,
        name: initialvalues?.name,
        mobileNo: initialvalues.mobileNo,
        email: initialvalues.email,
        rollNo: initialvalues.rollNo
      });
    }else{
      form.resetFields()
    }
  },[initialvalues])


  return (
    <div>
      {academicYearId ? (
        <Form
          form={form}
          colon={false}
          layout="vertical"
          onFinish={handleAddStudent}
        >
          <Row gutter={16}>
            <Col  xs={24} sm={12} md={8}>
              <Form.Item label="Name" name="name" className="form-lms-input" rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="RollNo" name="rollNo" >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Mobile" name="mobileNo" rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Email" name="email" rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={4} md={4}>
              <Form.Item label={true}>
              <Button htmlType="submit" className="w-100" style={{ background: "orange", color: "#fff" }}>Submit</Button>
              </Form.Item>
            </Col>
          </Row>
         
        </Form>
      ) : (
        "Please select filters"
      )}
    </div>
  );
};
