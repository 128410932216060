import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectRoute = ({ children }) => {
  const authenticated = useSelector(
    (state) => state.authencation.isAuthenticated
  );
  if (localStorage.getItem('localStoragedata')){
    return children
  }
  if (!authenticated) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default ProtectRoute;
