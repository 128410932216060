import {
  Select,
  Input,
  Form,
  Button,
  Row,
  Col,
  Checkbox,
  Modal,
  Switch,
  Radio,
} from "antd";
import React, { useEffect, useState } from "react";
import "../../styles/Quizcreate.css";
import {
  createQuiz,
  getTopicsInSubject,
  getModulesInTopic,
  getQuestionsInModule,
  getCustomCollegeQuiz,
  selectMCQInCustomQuizSubject,
  getAllQuestionsInCustomQuiz,
  replaceCustomMCQ,
  addQuestion,
  editQuestion,
  deleteAddedQuestion,
} from "../../store/services/quizApi";
import { useSelector } from "react-redux";
import { getSubjectOptions } from "../../store/services/quizApi";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Buffer } from "buffer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SelectCustomQuestions = () => {
  const [form] = Form.useForm();

  const [quizDetails, setQuizDetails] = useState();

  const [topicOptions, setTopicOptions] = useState([]);

  const [moduleOptions, setModuleOptions] = useState([]);

  const [questionsModified, setQuestionsModified] = useState(false);

  const [questions, setQuestions] = useState([]);

  const [selectSubject, setSelectSubject] = useState(null);

  const [saveStatus, setSaveStatus] = useState(true);

  const [questionAddModal, setQuestionAddModal] = useState(false);

  const [editQuestionDetails, setEditQuestion] = useState(null);

  const [showSelected, setShowSelected] = useState(false);

  const location = useLocation();

  const quizId = location?.state?.quizId || localStorage.getItem("quizId");

  const authenticate = useSelector((state) => state.authencation);

  const handleSelectSubject = (subject) => {
    // const selectedLength = questions.filter((item) => item.selected).length;
    const allow =
      questions?.filter((item) => item.edit && item.selected).length > 0
        ? false
        : true;
    if (allow) {
      setSelectSubject(subject);
      form.resetFields();
      setQuestions([]);
    } else {
      alert("Please save the selected questions");
    }
  };

  const fetchTopics = () => {
    getTopicsInSubject({
      subjectId: selectSubject?.subjectId,
    }).then((res) => {
      setTopicOptions(res?.topic);
    });
  };

  const fetchAllPrevSelQues = () => {
    getAllQuestionsInCustomQuiz({
      subjectId: selectSubject?.subjectId,
      quizId: quizId,
    }).then((res) => {
      if (res?.data) {
        let responseData = Buffer.from(res?.data, "base64").toString();
        responseData = JSON.parse(responseData);
        responseData = responseData.map((item) => ({
          ...item,
          isExpanded: false,
          selected: true,
          previous: true,
          edit: false,
        }));
        // console.log(responseData);
        setQuestions((prevQuestions) => {
          const uniqueQuestions = responseData.filter(
            (newQuestion) =>
              !prevQuestions.some(
                (existingQuestion) =>
                  existingQuestion.questionId == newQuestion.questionId
              )
          );
          return [...prevQuestions, ...uniqueQuestions];
        });
        // setQuestions((prevQuestions) => [...prevQuestions, ...responseData]);
      }
    });
  };

  useEffect(() => {
    if (authenticate.isAuthenticated) {
      getQuizDetails();

      if (location?.state?.quizId) {
        localStorage.setItem("quizid", location?.state?.quizId);
      }
    }
  }, [authenticate]);

  useEffect(() => {
    if (selectSubject?.subjectId) {
      fetchTopics();
      fetchAllPrevSelQues();
    }
  }, [selectSubject]);

  const resetValues = (value) => {
    if (value == "topics") {
      form.resetFields(["module"]);
    }
  };

  const handleChange = (field, value) => {
    if (field === "topics" && value !== null) {
      getModulesInTopic({
        topicId: value,
      }).then((res) => {
        // console.log(res?.module);
        setModuleOptions(res?.module);
        resetValues(field);
      });
    } else if (field === "module" && value !== null) {
    }
  };

  const handleAction = (action, index) => {
    if (action == "selected") {
      setQuestions((prevQuestions) => {
        return prevQuestions.map((question, i) => {
          if (question?.questionId === index) {
            return {
              ...question,
              selected: !question.selected,
            };
          }
          return question;
        });
      });
    }
    if (action == "delete") {
      // console.log(index);
      const deleteQuestion = questions?.filter((question, i) => {
        return question?.questionId == index;
      });
      // console.log(deleteQuestion);
      // console.log(deleteQuestion?.manualAdded);
      if (deleteQuestion[0]?.manualAdded) {
        const deleteBody = {
          quizId: quizId,
          subjectId: selectSubject?.subjectId,
          quizQuestionId: deleteQuestion[0]?.quizQuestionId,
        };
        // console.log(deleteBody);
        deleteAddedQuestion(deleteBody).then((res) => {
          console.log(res);
        });
      }
      setQuestions((prevQuestions) => {
        return prevQuestions.filter((question, i) => {
          return question?.questionId != index;
        });
      });
    }
    if (action == "replace") {
      replaceCustomMCQ({
        quizId: quizId,
        subjectId: selectSubject?.subjectId,
        quizQuestionId: index,
      }).then((res) => {
        let responseData = Buffer.from(res?.data, "base64").toString();
        responseData = JSON.parse(responseData);
        responseData = responseData.map((item) => ({
          ...item,
          isExpanded: false,
          selected: true,
          minimize: true,
          previous: true,
          edit: false,
        }));
        // console.log(responseData, index);
        // console.log(
        //   questions.map((question, i) =>
        //     question?.questionId == index ? responseData : question
        //   )
        // );
        setQuestions((prevQuestions) => {
          return prevQuestions.map((question, i) =>
            question?.questionId == index ? responseData[0] : question
          );
        });
      });
    }
    if (action == "edit") {
      const editQuestion = questions.filter((question, i) => {
        return question?.questionId == index;
      });

      // console.log(editQuestion)

      if (editQuestion.length > 0) {
        setEditQuestion(editQuestion[0]);
        setQuestionAddModal(true);
      }
    } else {
      setQuestions((prevQuestions) => {
        return prevQuestions.map((question, i) => {
          if (i === index) {
            return {
              ...question,
              isExpanded: !question.isExpanded,
            };
          }
          return question;
        });
      });
    }
  };

  const getQuizDetails = () => {
    if (quizId) {
      getCustomCollegeQuiz({
        facultyId: authenticate?.profileInfo?.facultyId,
        quizId: parseInt(quizId),
      }).then((res) => {
        // const quiz = res?.quiz?.filter((item)=> item?.quizId == location?.state?.quizId )
        // console.log(res?.quiz[0]);
        setQuizDetails(res?.quiz[0]);
      });
    }
  };

  const handleGetQuestions = (values) => {
    getQuestionsInModule({
      moduleId: values.module,
      // totalNoOfQuestions: parseInt(values.noOfQuestions),
    }).then((res) => {
      if (res?.data) {
        let responseData = Buffer.from(res?.data, "base64").toString();
        responseData = JSON.parse(responseData);
        responseData = responseData.map((item) => ({
          ...item,
          isExpanded: false,
          selected: false,
          minimize: true,
          previous: false,
          edit: true,
        }));

        setQuestions((prevQuestions) => {
          const uniqueQuestions = responseData.filter(
            (newQuestion) =>
              !prevQuestions.some(
                (existingQuestion) =>
                  existingQuestion.questionId == newQuestion.questionId
              )
          );
          return [...uniqueQuestions, ...prevQuestions ];
        });
        toast.success("Questions added");
        form.resetFields();
        // setQuestions((prevQuestions) => [...prevQuestions, ...responseData]);
      }
    });
  };

  const handleSelectedQuestions = () => {
    const selectedQuestions = questions.filter((item) => item.selected);
    if (selectedQuestions.length == selectSubject?.totalNoOfQuestions) {
      const body = {
        facultyId: authenticate?.profileInfo?.facultyId,
        quizId: quizId,
        subjectId: selectSubject?.subjectId,
        totalNoOfQuestions: selectSubject?.totalNoOfQuestions,
        quizSubjectQuestion: selectedQuestions,
      };

      selectMCQInCustomQuizSubject(body).then((res) => {
        // console.log(res);
        if (res?.status?.isSuccess == false) {
        } else {
          setQuestions([]);
          setSelectSubject(null);
        }
      });
    } else {
      alert(
        "Selected questions count should match with the total no of quesstions"
      );
    }
  };

  const handleCancelSelection = () => {
    //  alert("Are you sure want to cancel")
    form.resetFields();
    setSelectSubject(null);
    setQuestions([]);
  };

  const handleSaveStatus = () => {
    if (saveStatus) {
      if (
        questions?.filter((item) => item.edit && item.selected).length > 0 &&
        questions?.filter((item) => item.selected).length >=
          selectSubject?.totalNoOfQuestions
      ) {
        setSaveStatus(false);
      }
    }
  };

  useEffect(() => {
    handleSaveStatus();
  }, [questions]);

  // const handleAddQuestion = (body) => {
  //   addQuestion(body).then((res)=>{
  //        if (res?.data){
  //         let responseData = Buffer.from(res?.data, "base64").toString();
  //         responseData = JSON.parse(responseData);
  //         responseData = responseData.map((item) => ({
  //           ...item,
  //           isExpanded: false,
  //           selected: true,
  //           previous: true,
  //           edit: false,
  //         }));
  //         console.log(responseData);
  //         setQuestions((prevQuestions) => {
  //           const uniqueQuestions = responseData.filter(
  //             (newQuestion) =>
  //               !prevQuestions.some(
  //                 (existingQuestion) =>
  //                   existingQuestion.questionId == newQuestion.questionId
  //               )
  //           );
  //           return [...uniqueQuestions, ...prevQuestions];
  //       });

  //       toast.success("Question Added")
  //       return true

  //        }
  //   })
  // }
  const handleAddQuestion = (body) => {
    return new Promise((resolve, reject) => {
      if (
        editQuestionDetails != null &&
        editQuestionDetails?.previous == true
      ) {
        editQuestion(body)
          .then((res) => {
            if (res?.data) {
              let responseData = Buffer.from(res?.data, "base64").toString();
              responseData = JSON.parse(responseData);
              responseData = responseData.map((item) => ({
                ...item,
                isExpanded: false,
                selected: true,
                previous: true,
                edit: true,
              }));

              setQuestions((prevQuestions) => {
                return prevQuestions.map((question, i) =>
                  question?.questionId == editQuestionDetails?.questionId
                    ? responseData[0]
                    : question
                );
              });
              toast.success("Question edited");
              resolve(true);
              setQuestionAddModal(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        addQuestion(body)
          .then((res) => {
            if (res?.data) {
              let responseData = Buffer.from(res?.data, "base64").toString();
              responseData = JSON.parse(responseData);
              responseData = responseData.map((item) => ({
                ...item,
                isExpanded: false,
                selected: true,
                previous: true,
                edit: false,
              }));

              const updatedQuestions = [responseData[0], ...questions];
              setQuestions(updatedQuestions);
              toast.success("Question Added");
              resolve(true);
              setQuestionAddModal(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };

  return (
    <div className="p-4">
      
      <div className="mb-3 d-flex justify-content-between align-items-center">
      {selectSubject == null ? <Link to="/quiz/list" state={{ quiztype: "Custom Quiz"  }}>Back to Quiz List</Link>
        :<Button type="text" style={{color: '#007bff'}} onClick={()=>{setSelectSubject(null)}}>Back to Subject List</Button>}
        {/* <Button>Post Quiz</Button> */}
      </div>
      {selectSubject == null &&
      <div
        className="shadow p-3 pb-4 quiz-card rounded-5"
        style={{ borderRadius: "10px",width: '100%' }}
      >
        <table className="quiz-subjects-table">
          <tr>
            <th>Subject</th>
            <th>Questions</th>
            <th>Select Questions</th>
          </tr>
          {quizDetails?.quizSubject?.map((item,index) => {
            return (
              <tr key={index}>
                <td>{item?.subjectName}</td>
                <td>
                  <Input
                    name={item?.name + "_total"}
                    value={item?.totalNoOfQuestions}
                    // onChange={(e) =>
                    //   handleSubjectNumbers(
                    //     e,
                    //     item?.name,
                    //     "totalNoOfQuestions"
                    //   )
                    // }
                  />
                </td>
                <td>
                  <Button onClick={() => handleSelectSubject(item)}>
                    Select Questions
                  </Button>
                </td>
              </tr>
            );
          })}
        </table>
      </div>}

      {selectSubject && (
        <div
          style={{ background: "white", borderRadius: "10px" }}
          className="shadow p-2 mb-4"
        >
          <div
            style={{ width: "100%", background: "aliceblue" }}
            className="p-2"
          >
            <Form
              form={form}
              colon={false}
              layout="vertical"
              onFinish={handleGetQuestions}
            >
              <Row gutter={16}>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item
                    label="Select Topics"
                    name="topics"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Topics"
                      onChange={(value) => handleChange("topics", value)}
                    >
                      {topicOptions?.map((item) => {
                        return (
                          <Select.Option value={item?.topicId}>
                            {item?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item
                    label="Select Module"
                    name="module"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Module"
                      // onChange={(value) => handleChange("module", value)}
                    >
                      {moduleOptions?.map((item) => {
                        return (
                          <Select.Option value={item?.moduleId}>
                            {item?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={6}>
                  <Form.Item
                    label="No Of Questions"
                    name="noOfQuestions"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter No Of Questions" />
                  </Form.Item>
                </Col> */}
                <Col xs={24} sm={12} md={4}>
                  <Form.Item label={true}>
                    <Button
                      style={{
                        background: "orange",
                        width: "100%",
                        boxShadow: "0 2px 0 rgba(5,145,255,.1)",
                      }}
                      htmlType="submit"
                    >
                      Get Questions
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                  <Form.Item label={true}>
                    <Button
                      style={{
                        background: "orange",
                        width: "100%",
                        boxShadow: "0 2px 0 rgba(5,145,255,.1)",
                      }}
                      onClick={() => {
                        setQuestionAddModal(true);
                        setEditQuestion(null);
                      }}
                    >
                      Add Question
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                  <Form.Item label={true}>
                    <div>
                      <span>
                        <b>Only Selected</b>
                      </span>
                      &nbsp;&nbsp;
                      <Switch
                        size="small"
                        checked={showSelected}
                        onChange={() => setShowSelected(!showSelected)}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <div
            className="d-flex justify-content-between  p-3 pb-4 mb-2"
            style={{ background: "aliceblue" }}
          >
            <div>
              <b>Subject Name:</b> {selectSubject?.subjectName}
            </div>
            <div>
              <b>Total no of questions:</b> {selectSubject?.totalNoOfQuestions}
            </div>
            <div>
              <b>Selected Questions:</b>{" "}
              {questions?.filter((item) => item.selected).length}
            </div>
            <div>
              <Button
                onClick={() => handleSelectedQuestions()}
                style={{
                  backgroundColor: "rgb(137, 233, 255)",
                  width: "100px",
                }}
                title="Only Selected questions will be save"
                disabled={saveStatus}
              >
                Save
              </Button>
            </div>
            <div>
              <Button
                onClick={() => handleCancelSelection()}
                style={{ backgroundColor: "red", width: "100px" }}
              >
                Cancel
              </Button>
            </div>
          </div>

          <div style={{height: '60vh',overflowY: 'scroll'}}>

          {questions?.map((question, index) => {
            if (false) {
              return (
                <div
                  className="question-box"
                  style={{
                    border: question?.selected
                      ? "2px solid #89e9ff"
                      : "2px solid black",
                  }}
                >
                  <div className="question-box-item justify-content-between">
                    <div className="selectedquestion">
                      <Checkbox
                        checked={question?.selected}
                        onClick={() =>
                          handleAction("selected", question?.questionId)
                        }
                      />
                    </div>
                    <div>
                      {/* <div
                        dangerouslySetInnerHTML={{ __html: question.question }}
                      ></div> */}
                      <div className="mb-2">
                        <span
                          style={{
                            backgroundColor: "#89e9ff",
                            padding: "5px",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                        >
                          #{question?.moduleName}
                        </span>
                        <span
                          style={{
                            backgroundColor: "#89e9ff",
                            padding: "5px",
                            borderRadius: "5px",
                          }}
                        >
                          #{question?.topicName}
                        </span>
                      </div>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() =>
                          handleAction("delete", question?.questionId)
                        }
                        style={{ cursor: "pointer", color: "red" }}
                      />
                    </div>
                  </div>
                </div>
              );
            } else {
              if (showSelected && question?.selected == false) {
                return null;
              }
              return (
                <div
                  className="question-box"
                  style={{
                    border: question?.selected
                      ? "2px solid #89e9ff"
                      : "2px solid black",
                  }}
                  key = {question?.questionId}
                >
                  <div className="question-box-item justify-content-between">
                    <div className="selectedquestion">
                      <Checkbox
                        checked={question?.selected}
                        onClick={() =>
                          handleAction("selected", question?.questionId)
                        }
                      />
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() =>
                          handleAction("delete", question?.questionId)
                        }
                        style={{ cursor: "pointer", color: "red" }}
                      />
                    </div>
                  </div>
                  <div className="mb-2">
                    {question?.manualAdded ? (
                      <span
                        style={{
                          backgroundColor: "#89e9ff",
                          padding: "5px",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      >
                        #Manual added
                      </span>
                    ) : (
                      <>
                        <span
                          style={{
                            backgroundColor: "#89e9ff",
                            padding: "5px",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                        >
                          #{question?.moduleName}
                        </span>
                        <span
                          style={{
                            backgroundColor: "#89e9ff",
                            padding: "5px",
                            borderRadius: "5px",
                          }}
                        >
                          #{question?.topicName}
                        </span>
                      </>
                    )}
                  </div>
                  <div
                    onClick={() =>
                      handleAction("selected", question?.questionId)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="question-box-item">
                      <div className="item-1">
                        <p>
                          <b>S/no:</b>
                        </p>
                      </div>
                      <div className="item-2">{index + 1}</div>
                    </div>
                    {/* <div className="question-box-item">
                <div className="item-1">
                  <b>Question Type:</b>
                </div>
                <div className="item-2">{question?.questionType}</div>
              </div> */}

                    {/* <div className="question-box-item">
                <div className="item-1">
                  <b>Question ID:</b>
                </div>
                <div className="item-2">{question?.questionId}</div>
              </div> */}
                    <div className="question-box-item">
                      <div className="item-1">
                        <p>
                          <b>Question:</b>
                        </p>
                      </div>
                      <div
                        className="item-2"
                        dangerouslySetInnerHTML={{ __html: question?.question }}
                      ></div>
                    </div>
                    <div className="question-box-item">
                      <div className="item-1">
                        <p>
                          <b>Answers:</b>
                        </p>
                      </div>
                      <div className="item-2">
                        {JSON.parse(question?.answer).map((item, index) => {
                          return (
                            <div className="d-flex">
                              {index == 0
                                ? "A:"
                                : index == 1
                                ? "B:"
                                : index == 2
                                ? "C:"
                                : index == 3
                                ? "D:"
                                : index == 4
                                ? "E:"
                                : null}
                              &nbsp;&nbsp;
                              <p dangerouslySetInnerHTML={{ __html: item }}></p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="question-box-item">
                      <div className="item-1">
                        <b>Correct answer:</b>
                      </div>
                      <div
                        className="item-2"
                        dangerouslySetInnerHTML={{
                          __html: question.correctAnswerIndex,
                        }}
                      ></div>
                    </div>
                    {/* <div className="question-box-item">
                <b>Correct answer:</b> {question?.correctAnswerIndex}
              </div> */}
                    {/* <div className="d-flex justify-content-end"></div> */}
                  </div>
                  <div className="d-flex justify-content-between">
                    <Button onClick={() => handleAction("toggle", index)}>
                      Explanation
                    </Button>
                    {/* {
                      question?.previous ?  */}
                    {/* <Button
                      onClick={() =>
                        handleAction("replace", question?.questionId)
                      }
                      disabled={!question?.previous || question?.manualAdded}
                      title={
                        !question?.previous
                          ? "Replace button only for saved questions"
                          : null
                      }
                    >
                      Replace
                    </Button> */}
                    <Button
                      onClick={() => handleAction("edit", question?.questionId)}
                    >
                      Edit
                    </Button>
                    {/* } */}
                  </div>
                  {question?.isExpanded && (
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.explanation,
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            }
          })}

          </div>

          {questions?.length == 0 ? (
            <div className="no-records-box">
              <h3>No Records</h3>
            </div>
          ) : null}
        </div>
      )}
      {questionAddModal == true ?<Modal
        footer={null}
        open={questionAddModal}
        title={editQuestionDetails == null ? "Add Question" : "Edit Question"}
        onOk={() => {
          setQuestionAddModal(false);
        }}
        onCancel={() => {
          setQuestionAddModal(false);
          setEditQuestion(null);
        }}
        width={1000}
      >
       <AddQuestion
          selectSubject={selectSubject}
          addQuestionApi={handleAddQuestion}
          editQuestion={editQuestionDetails}
        />
      </Modal>:null}
    </div>
  );
};

export default SelectCustomQuestions;

// questions.filter((item) => item.edit && item.selected)
//   .length > 0
//   ? questions.filter((item) => item.selected).length  >=
//     selectSubject?.totalNoOfQuestions
//     ? false
//     : true
//   : true

const AddQuestion = ({ selectSubject, addQuestionApi, editQuestion }) => {

  const [form] = Form.useForm();

  const [questionState, setQuestionState] = useState({
    question: "",
    explanation: "",
    choice_0: "",
    choice_1: "",
    choice_2: "",
    choice_3: "",
    correctAnswerIndex: "",
  });

  const [render,setRender] = useState(false)

  const location = useLocation();

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
      // ['undo', 'redo'],
    ],
    // history: {
    //   delay: 1000,
    //   maxStack: 50,
    //   userOnly: true
    // }
  };

  const handleSubmit = async (values) => {
    // for (const key in questionState) {
    //   if (!questionState[key]) {
    //     alert(`Please fill in the ${key.replace(/_/g, " ")} field.`);
    //     return; // Stop further execution
    //   }
    // }

    const body = {
      quizId: location?.state?.quizId,
      subjectId: selectSubject?.subjectId,
      questionText: values?.question,
      answer: JSON.stringify([
        values?.choice_0,
        values?.choice_1,
        values?.choice_2,
        values?.choice_3,
      ]),
      correctAnswerIndex:
      values?.correctAnswerIndex == "choice_0"
          ? 0
          : values?.correctAnswerIndex == "choice_1"
          ? 1
          : values?.correctAnswerIndex == "choice_2"
          ? 2
          : 3,
      explanation: "<p>" + values?.explanation + "</p>",
      quizQuestionId: editQuestion?.quizQuestionId,
      questionId: editQuestion?.questionId,
    };

    // console.log(body);


    const status = await addQuestionApi(body);

    if (status) {
      // setQuestionState({
      //   question: "",
      //   explanation: "",
      //   choice_0: "",
      //   choice_1: "",
      //   choice_2: "",
      //   choice_3: "",
      //   correctAnswerIndex: "",
      // });
      form.resetFields()
    }
  };

  const handleChange = (type, e) => {
    // setQuestionState((prevState) => ({
    //   ...prevState,
    //   [type]: e,
    // }));

    form.setFieldsValue({
      [type]:e
    });
  };

  useEffect(() => {
    if (editQuestion != null) {
      
      const choices = JSON.parse(editQuestion?.answer);

      form.setFieldsValue({
        "question": editQuestion?.question,
        "explanation": editQuestion?.explanation,
        "choice_0": choices[0],
        "choice_1": choices[1],
        "choice_2": choices[2],
        "choice_3": choices[3],
        "correctAnswerIndex":
          editQuestion?.correctAnswerIndex == "<b>A</b>"
            ? "choice_0"
            : editQuestion?.correctAnswerIndex == "<b>B</b>"
            ? "choice_1"
            : editQuestion?.correctAnswerIndex == "<b>C</b>"
            ? "choice_2"
            : "choice_3",
      });

      setRender(!render)
      
    } else {
      // setQuestionState({
      //   question: "",
      //   explanation: "",
      //   choice_0: "",
      //   choice_1: "",
      //   choice_2: "",
      //   choice_3: "",
      //   correctAnswerIndex: "",
      // });
      form.resetFields()
      setRender(!render)
    }
  }, [editQuestion]);

  return (
    <div className="addquestionbox">
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <div>
          <Form.Item
            name="question"
            label="Question :"
            rules={[{ required: true, message: "Please enter the question" }]}
          >
            <div>
              <ReactQuill
                theme="snow"
                onChange={(html) => handleChange("question", html)}
                value={form.getFieldValue('question')}
                modules={modules}
              />
            </div>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="choice_0"
            label="Choice 0 :"
            rules={[{ required: true, message: "Please enter the Choice 0" }]}
          >
            <div>
              <ReactQuill
                theme="snow"
                onChange={(html) => handleChange("choice_0", html)}
                value={form.getFieldValue('choice_0')}
                modules={modules}
              />
            </div>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="choice_1"
            label="Choice 1 :"
            rules={[{ required: true, message: "Please enter the Choice 1" }]}
          >
            <div>
              <ReactQuill
                theme="snow"
                onChange={(html) => handleChange("choice_1", html)}
                value={form.getFieldValue('choice_1')}
                modules={modules}
              />
            </div>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="choice_2"
            label="Choice 2 :"
            rules={[{ required: true, message: "Please enter the Choice 2" }]}
          >
            <div>
              <ReactQuill
                theme="snow"
                onChange={(html) => handleChange("choice_2", html)}
                value={form.getFieldValue('choice_2')}
                modules={modules}
              />
            </div>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="choice_3"
            label="Choice 3 :"
            rules={[{ required: true, message: "Please enter the Choice 3" }]}
          >
            <div>
              <ReactQuill
                theme="snow"
                onChange={(html) => handleChange("choice_3", html)}
                value={form.getFieldValue('choice_3')}
                modules={modules}
              />
            </div>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            label="Correct Answer"
            name="correctAnswerIndex"
            rules={[
              { required: true, message: "Please select any one option" },
            ]}
          >
            <Radio.Group>
              <Radio
                value="choice_0"
                onChange={() => handleChange("correctAnswerIndex", "choice_0")}
              >
                {" "}
                Choice 0{" "}
              </Radio>
              <Radio
                value="choice_1"
                onChange={() => handleChange("correctAnswerIndex", "choice_1")}
              >
                {" "}
                Choice 1{" "}
              </Radio>
              <Radio
                value="choice_2"
                onChange={() => handleChange("correctAnswerIndex", "choice_2")}
              >
                {" "}
                Choice 2{" "}
              </Radio>
              <Radio
                value="choice_3"
                onChange={() => handleChange("correctAnswerIndex", "choice_3")}
              >
                {" "}
                Choice 3{" "}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="explanation"
            label="Explanation :"
            rules={[
              { required: true, message: "Please enter the explanation" },
            ]}
          >
            <div>
              <ReactQuill
                theme="snow"
                onChange={(html) => handleChange("explanation", html)}
                value={form.getFieldValue('explanation')}
                modules={modules}
              />
            </div>
          </Form.Item>
        </div>
        <div className="mt-4">
          <Form.Item>
            <Button
              htmlType="submit"
              // onClick={() => {
              //   handleSubmit();
              // }}
              style={{
                background: "orange",
                width: "100%",
                boxShadow: "0 2px 0 rgba(5,145,255,.1)",
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

